import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "components/button";
import Input from "components/input";
import request from "request";
import { nationality, types_of_product } from "utils/constants";
import styles from "./create-modal.module.scss";

const Modal = ({ onClose }: { onClose: () => void }) => {
  const [nationalityVar, setNationality] = useState("");
  const [sum, setSum] = React.useState("");
  const [interestRate, setInterestRate] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [type_of_product, setTypeOfProduct] = React.useState("");
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name:
      | "inn"
      | "sum"
      | "interestRate"
      | "duration"
      | "nationality"
      | "type_of_product"
      | "customerName";
    value: string;
  } | null>(null);

  const navigate = useNavigate();

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (type_of_product.trim().length === 0) {
      return setValidError({
        name: "type_of_product",
        value: "Type of product is required",
      });
    }
    // else if (sum.trim().length === 0) {
    //   return setValidError({
    //     name: "sum",
    //     value: "Amount is required",
    //   });
    // } else if (
    //   type_of_product === "PERSONAL LOAN" &&
    //   Number(sum) > 750000 &&
    //   nationalityVar === "expat"
    // ) {
    //   return setValidError({
    //     name: "sum",
    //     value:
    //       "The loan amount for a personal loan should not be more than 750 000 in case of Expat nationals",
    //   });
    // } else if (
    //   type_of_product === "PERSONAL LOAN" &&
    //   Number(sum) > 1000000 &&
    //   nationalityVar === "uae_national"
    // ) {
    //   return setValidError({
    //     name: "sum",
    //     value:
    //       "The loan amount for a personal loan should not be more than 1 000 000 in case of UAE nationals",
    //   });
    // } else if (Number(sum) > 2000000) {
    //   return setValidError({
    //     name: "sum",
    //     value: "The loan amount should not be more than 2 000 000",
    //   });
    // } else if (Number(sum) < 15000) {
    //   return setValidError({
    //     name: "sum",
    //     value: "The loan amount should not be less than 15 000",
    //   });
    // }
    // else if (interestRate.trim().length === 0) {
    //   return setValidError({
    //     name: "interestRate",
    //     value: "Interest rate is required",
    //   });
    // }
    else if (customerName.trim().length === 0) {
      return setValidError({
        name: "customerName",
        value: "Customer name is required",
      });
    } else if (!nationalityVar) {
      return setValidError({
        name: "nationality",
        value: "Nationality is required",
      });
    } else setValidError(null);
    setPending(true);
    request
      .post("/applications", {
        // loan_amount: sum.replaceAll(",", ""),
        // interest_rate: Number(interestRate.replaceAll(" ", "")) / 100,
        nationality: nationalityVar,
        type_of_product,
        customer_name: customerName,
      })
      .then((res) => res.data)
      .then((data) => {
        navigate(`application/${data.id}/scoring`);
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
        onSubmit={handleCreate}
      >
        <div className={styles.title}>New application</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="Type of Product"
              id="type_of_product"
              value={type_of_product}
              type="select"
              options={types_of_product.map((type_of_product) => ({
                key: type_of_product,
                value: type_of_product,
              }))}
              onSelect={(option) => setTypeOfProduct(option.key)}
            />
            {validError?.name === "type_of_product" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Customer name"
              value={customerName}
              type="string"
              onInput={(value) => {
                if (value.length > 25) return;
                setCustomerName(value);
              }}
            />
            {validError?.name === "type_of_product" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Customer type"
              type="select"
              value={nationalityVar}
              options={nationality.map((nationality) => ({
                key: nationality,
                value: nationality === "expat" ? "Expat" : "UAE National",
              }))}
              onSelect={(option) => setNationality(option.key)}
            />
            {validError?.name === "nationality" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Create
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
