import type { FormField, Options } from "./useForm";

export const processFormItem = (
  item: string,
  formItem: FormField
): string | number | null => {
  if (isOption(formItem)) {
    return processOption(formItem);
  }

  if (isNaN(Number(formItem))) {
    return formItem;
  }

  if (item === "interest_rate") {
    return parseNumber(formItem);
  }

  return parseNumber(formItem);
};

export const isOption = (value: FormField): value is Options => {
  return (
    typeof value === "object" &&
    value !== null &&
    "key" in value &&
    "value" in value
  );
};

export const processOption = (option: Options): string | number => {
  return isNaN(Number(option.key)) ? option.key : parseNumber(option.key);
};

export const parseNumber = (value: string) =>
  Number(value?.replaceAll(" ", "").replaceAll(",", ""));
