import { useLayoutEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import Loading from "components/loading";
import Navbar from "components/navbar";
import { useDispatch, useSelector } from "store/hooks";
import styles from "./application.module.scss";

const ApplicationLayout = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();
  const application = useSelector((state) => state.application);
  const constants = useSelector((state) => state.constants);

  useLayoutEffect(() => {
    if (id)
      dispatch.application.FETCH_APPLICATION_BY_ID({
        id,
        errorCallback() {
          navigate(-1);
        },
      });
    dispatch.constants.FETCH();
  }, [dispatch.application, dispatch.constants, id, navigate]);

  return (
    <div className={styles.applicationLayout} id="application-layout">
      {!application || !constants ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.navbar}>
            <Navbar resultBlocked={application?.scoring_log === null} />
          </div>
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationLayout;
