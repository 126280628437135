const transcript = {
  userRole: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case "admin":
          return "Admin";
        case "root":
          return "Super admin";
        case "team_lead":
          return "Team lead";
        default:
          return "Credit expert";
      }
    },
  },
};

export default transcript;
