import classNames from "classnames";
import Icon from "icons";
import { useEffect, useState } from "react";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import { CommentBox } from "./CommentBox";
import styles from "./result-box.module.scss";

const ResultBox = () => {
  const dispatch = useDispatch();
  const application = useSelector((state) => state.application);

  const [editComment, setEditComment] = useState(
    !application?.comment && application?.is_approved === null
  );
  const [comment, setComment] = useState("");
  const [pending, setPending] = useState(false);

  const handleSubmitComment = () => {
    setPending(true);
    request
      .post("applications/comment/" + application?.id, {
        message: comment.trim() === "" ? "" : comment,
      })
      .then(() =>
        dispatch.application.FETCH_APPLICATION_BY_ID({
          id: String(application?.id),
        })
      )
      .finally(() => setPending(false));
  };

  useEffect(() => {
    setComment(application?.comment ?? "");
  }, [application?.comment]);

  return (
    <>
      {application?.scoring_log && (
        <div className={styles.resultBox}>
          <div className={styles.resultBox_title}>Application decision</div>
          <div className={styles.finalResult}>
            <Icon
              size={120}
              name={application?.is_approved ? "tickCircle" : "closeFilled"}
              className={classNames(
                application?.is_approved ? styles.green : styles.red
              )}
            />
            <div
              className={classNames(
                styles.finalResultText,
                application?.is_approved ? styles.green : styles.red
              )}
            >
              {application?.is_approved ? "Approved" : "Rejected"}
            </div>
          </div>
          <div className={styles.resultBox_resultText}>
            {application?.scoring_log?.is_approved !== null && (
              <div className={styles.item}>
                <div className={styles.key}>
                  Scoring ({application?.scoring_log?.prediction}%):
                </div>
                <div
                  className={classNames(
                    styles.value,
                    application?.scoring_log === null
                      ? styles.grey
                      : application?.scoring_log?.is_approved
                        ? styles.green
                        : styles.red
                  )}
                >
                  {application?.scoring_log === null
                    ? "No decision, yet"
                    : application?.scoring_log?.is_approved
                      ? "Approved"
                      : "Rejected"}
                </div>
              </div>
            )}
            {application?.dsr_log &&
              application?.dsr_log.is_approved !== null && (
                <div className={styles.item}>
                  <div className={styles.key}>
                    DSR ({Number(application.dsr_log?.value).toFixed(2)}
                    %):
                  </div>
                  <div
                    className={classNames(
                      styles.value,
                      application?.dsr_log?.is_approved
                        ? styles.green
                        : styles.red
                    )}
                  >
                    {application?.dsr_log?.is_approved
                      ? "Approved"
                      : "Rejected"}
                  </div>
                </div>
              )}
            {application?.aecb_score_log?.is_approved !== null && (
              <div className={styles.item}>
                <div className={styles.key}>
                  Agency Score ({application?.aecb_score_log?.value}):
                </div>
                <div
                  className={classNames(
                    styles.value,
                    application?.aecb_score_log?.is_approved
                      ? styles.green
                      : styles.red
                  )}
                >
                  {application?.aecb_score_log?.is_approved
                    ? "Approved"
                    : "Rejected"}
                </div>
              </div>
            )}

            {application?.type_of_product !== "PERSONAL LOAN" &&
              application?.type_of_product !== "CAR LOAN" &&
              application?.ltv_log &&
              application?.ltv_log?.is_approved !== null && (
                <div className={styles.item}>
                  <div className={styles.key}>
                    LTV ({(application?.ltv_log?.value).toFixed(2)}
                    %):
                  </div>
                  <div
                    className={classNames(
                      styles.value,
                      application?.ltv_log?.is_approved
                        ? styles.green
                        : styles.red
                    )}
                  >
                    {application?.ltv_log?.is_approved
                      ? "Approved"
                      : "Rejected"}
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
      <CommentBox
        comment={comment}
        setComment={setComment}
        handleSubmitComment={handleSubmitComment}
        pending={pending}
        editComment={editComment}
        setEditComment={setEditComment}
        title={"RM comment"}
      />
    </>
  );
};

export default ResultBox;
