import { AxiosError } from "axios";
import classNames from "classnames";
import Button from "components/button";
import ButtonLoader from "components/button-loader";
import Input from "components/input";
import Icon from "icons";
import * as math from "mathjs";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import request from "request";
import { formatDateTime } from "utils/formatDateTime";
import prettierNumber from "utils/prettier-number";
import Modal from "./components/create-modal";
import modalStyles from "./components/create-modal.module.scss";
import home from "./home";
import styles from "./home.module.scss";

const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);

const Home = () => {
  const navigate = useNavigate();
  const [from, setFrom] = React.useState<Date>(firstDayOfYear);
  const [to, setTo] = React.useState<Date>(lastDayOfYear);
  const [page, setPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(1);
  const [newApplicationModal, setNewApplicationModal] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [exportExcelPending, setExportExcelPending] = React.useState(false);
  const resultRef = React.useRef<HTMLDivElement | null>(null);

  const [data, setData] = React.useState<home.result[]>([]);

  const handleFilter = async (FROM?: Date, TO?: Date) => {
    const fd = (FROM ?? from)
      .toLocaleDateString("en-GB")
      .split("/")
      .reverse()
      .join("-");
    const td = (TO ?? to)
      .toLocaleDateString("en-GB")
      .split("/")
      .reverse()
      .join("-");
    setPending(true);
    setPage(1);
    resultRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    request
      .get(`/applications/history?fd=${fd}&td=${td}&page=${page}`)
      .then((res) => res.data)
      .then((d) => {
        setData(d.records);
        setRowCount(d.total_count);
      })
      .finally(() => setPending(false));
  };

  const handleClear = () => {
    setFrom(firstDayOfYear);
    setTo(lastDayOfYear);
    handleFilter(firstDayOfYear, lastDayOfYear);
  };

  const handleExportExcel = () => {
    const fd = from.toLocaleDateString("en-GB").split("/").reverse().join("-");
    const td = to.toLocaleDateString("en-GB").split("/").reverse().join("-");
    setExportExcelPending(true);
    request({
      method: "GET",
      url: `/applications/export?start_date=${fd}&end_date=${td}`,
      responseType: "blob",
    })
      .then((res) => res.data)
      .then((data) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fd + "," + td + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .finally(() => setExportExcelPending(false));
  };

  React.useEffect(() => {
    const handleLoadRows = async () => {
      const fd = from
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");
      const td = to.toLocaleDateString("en-GB").split("/").reverse().join("-");
      setPending(true);
      resultRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      request
        .get(`/applications/history?fd=${fd}&td=${td}&page=${page}`)
        .then((res) => res.data)
        .then((d) => {
          setData(d.records);
          setRowCount(d.total_count);
        })
        .catch((err: unknown) => {
          if (!(err instanceof AxiosError)) return;
          if (err.response?.status === 403) {
            navigate("/users");
          }
        })
        .finally(() => setPending(false));
    };
    handleLoadRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className={styles.results} ref={resultRef}>
      <div className={styles.container}>
        <div className={styles.tools}>
          <div className={styles.filterBox}>
            <div className={styles.input}>
              <Input
                title="From"
                value={from}
                type="date"
                onSetDate={(d) => setFrom(d)}
              />
            </div>
            <div className={styles.input}>
              <Input
                title="To"
                value={to}
                type="date"
                onSetDate={(d) => setTo(d)}
              />
            </div>
            <div className={styles.buttonGroup}>
              <Button pending={pending} onClick={() => handleFilter()}>
                Filter
              </Button>
              <Button pending={pending} color="red" onClick={handleClear}>
                Clear
              </Button>
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              pending={exportExcelPending || pending}
              color="orange"
              onClick={handleExportExcel}
            >
              Export .XLSX file
            </Button>
            <Button color="green" onClick={() => setNewApplicationModal(true)}>
              New application
            </Button>
          </div>
        </div>
        {pending && (
          <div className={styles.tableLoading}>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
          </div>
        )}
        {!pending && data.length !== 0 && (
          <div className={styles.table}>
            {data.map((item) => (
              <Link
                to={`application/${item.id}/scoring`}
                className={styles.tableRowWrap}
                key={item.id}
              >
                <div className={styles.tableRow}>
                  <div className={styles.item}>
                    <span>Application ID</span> {item.id}
                  </div>
                  <div className={styles.item}>
                    <span>Expert</span>
                    {item?.created_by.email}
                  </div>
                  <div className={styles.item}>
                    <span>Create date</span>
                    {formatDateTime(item.created_at)}
                  </div>
                  <div className={styles.item}>
                    <span>Type of product</span>
                    {item.type_of_product}
                  </div>
                  <div className={styles.item}>
                    <span>Amount</span>
                    {/* {prettierNumber(item.loan_amount)} AED */}
                    {item.loan_amount
                      ? `${prettierNumber(item.loan_amount)} AED`
                      : "–"}{" "}
                  </div>
                  <div className={styles.item}>
                    <span>Bank interest rate</span>
                    {item.interest_rate
                      ? `${Number(item.interest_rate)?.toFixed(2)} %`
                      : "–"}
                  </div>
                  <div
                    className={classNames(
                      styles.item,
                      item.is_approved === true && styles.green,
                      item.is_approved === false &&
                        item.scoring_log &&
                        styles.red
                    )}
                  >
                    <span>Result</span>
                    {item.scoring_log === null
                      ? "Draft"
                      : item.is_approved
                        ? "Approved"
                        : "Rejected"}
                  </div>
                  <div className={styles.item}>
                    <span>Probability of approval</span>
                    {item?.scoring_log?.prediction
                      ? `${item?.scoring_log?.prediction}%`
                      : "–"}
                  </div>
                  <div className={styles.item}>
                    <span>Threshold</span>
                    {item.scoring_log?.threshold
                      ? `${item.scoring_log?.threshold}%`
                      : "–"}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
        {!pending && data.length === 0 && (
          <div className={styles.tableIsEmptyInfo}>No applications</div>
        )}
        {rowCount > 15 && (
          <div className={styles.pagination}>
            <button
              className={classNames(styles.arrow, page <= 1 && styles.disabled)}
              onClick={() => setPage(math.max(page - 1, 1))}
            >
              <Icon name="arrowLeft" />
            </button>
            <div className={styles.label}>
              {page} / {math.ceil(rowCount / 15)}
            </div>
            <button
              className={classNames(
                styles.arrow,
                page * 15 >= rowCount && styles.disabled
              )}
              onClick={() =>
                setPage(math.min(page + 1, math.ceil(rowCount / 15)))
              }
            >
              <Icon name="arrowRight" />
            </button>
          </div>
        )}
      </div>
      <CSSTransition
        in={!!newApplicationModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <Modal onClose={() => setNewApplicationModal(false)} />
      </CSSTransition>
    </div>
  );
};

export default Home;
