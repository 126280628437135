import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useDebounce } from "utils/hooks";
import { processFormItem } from "./formUtils";
import { handleCalculateMonthlyInstallment } from "./handleCalculateMonthlyInstallment";

export interface Options {
  key: string;
  value: string;
}

export type TypeOfProduct = "CAR LOAN" | "PERSONAL LOAN" | "HOUSING LOAN";

export type FormField = string | Options;

interface BaseFormState {
  loan_amount: string;
  interest_rate: string;
  duration_at_disbursement_months: string;
  [key: string]: FormField;
}

type HookParams<T extends BaseFormState> = {
  initialState: T;
  typeOfProduct: TypeOfProduct;
};

type HookReturn<T extends BaseFormState> = {
  form: T;
  setForm: Dispatch<SetStateAction<T>>;
  formDebounced: T;
  monthlyInstallment: string;
  setMonthlyInstallment: Dispatch<SetStateAction<string>>;
  isFormValid: boolean;
  handleInputChange: (field: keyof T, value: string) => void;
  handleSelectChange: (field: keyof T, option: Options) => void;
  prepareRequestData: (
    form: T,
    type: TypeOfProduct
  ) => Partial<Record<keyof T, string | number | null>>;
};

const formFields: (keyof BaseFormState)[] = [
  "loan_amount",
  "interest_rate",
  "duration_at_disbursement_months",
];

const carLoanProperties: string[] = ["car_price"];
const personalLoanProperties: string[] = [];
const housingLoanProperties: string[] = ["property_number", "property_value"];

export function useForm<T extends BaseFormState>({
  initialState,
  typeOfProduct,
}: HookParams<T>): HookReturn<T> {
  const [form, setForm] = useState<T>(initialState);
  const [monthlyInstallment, setMonthlyInstallment] = useState<string>("");
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const formDebounced = useDebounce(form, 500);

  const handleInputChange = useCallback(
    (field: keyof T, value: string) => {
      setForm((prev) => ({ ...prev, [field]: value }));

      if (formFields.includes(field as keyof BaseFormState)) {
        // @ts-ignore
        const amount = field === "loan_amount" ? value : form.loan_amount;
        // @ts-ignore
        const rate = field === "interest_rate" ? value : form.interest_rate;
        // @ts-ignore
        const duration =
          field === "duration_at_disbursement_months"
            ? value
            : // @ts-ignore
              form.duration_at_disbursement_months;

        const newMonthlyInstallment = handleCalculateMonthlyInstallment(
          amount,
          rate,
          duration
        );
        setMonthlyInstallment(newMonthlyInstallment);
      }
    },
    [form]
  );

  const handleSelectChange = useCallback((field: keyof T, option: Options) => {
    setForm((prev) => ({ ...prev, [field]: option }));
  }, []);

  const prepareRequestData = (
    formObject: T,
    type: TypeOfProduct
  ): Partial<Record<keyof T, string | number | null>> => {
    const requestObject: Partial<Record<keyof T, string | number | null>> = {};
    const loanTypeProperties: Record<TypeOfProduct, string[]> = {
      "CAR LOAN": carLoanProperties,
      "PERSONAL LOAN": personalLoanProperties,
      "HOUSING LOAN": housingLoanProperties,
    };

    for (const item in form) {
      if (item in formObject) {
        const formItem = form[item as keyof typeof form];

        if (
          Object.entries(loanTypeProperties).some(
            ([loanType, properties]) =>
              properties.includes(item) && type !== (loanType as TypeOfProduct)
          )
        ) {
          requestObject[item] = null;
          continue;
        }

        requestObject[item] = processFormItem(item, formItem);
      }
    }

    return requestObject;
  };

  return {
    form,
    setForm,
    formDebounced,
    monthlyInstallment,
    setMonthlyInstallment,
    handleInputChange,
    handleSelectChange,
    prepareRequestData,
    isFormValid,
  };
}
