import { createModel } from "@rematch/core";
import { AxiosError } from "axios";
import global from "global";
import request from "request";

const application = createModel()({
  state: null as global.application | null,
  reducers: {
    SET(state, payload: global.application) {
      if (state && JSON.stringify(state) === JSON.stringify(payload))
        return state;
      else return { ...state, ...payload };
    },
    REMOVE() {
      return null;
    },
  },
  effects: (dispatch) => ({
    async FETCH_APPLICATION_BY_ID({
      id,
      callback,
      errorCallback,
    }: {
      id: string;
      callback?: () => void;
      errorCallback?: () => void;
    }) {
      try {
        dispatch.application.REMOVE();
        const response = await request.get("applications/data/" + id);
        const data = await response.data;
        dispatch.application.SET(data);
        callback?.();
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 404) {
            if (errorCallback) {
              errorCallback();
            }
          }
        }
      }
    },
  }),
});

export default application;
