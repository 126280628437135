import { FunctionComponent, ReactNode } from "react";
import styles from "./Info.module.scss";

interface Props {
  title: string;
  children: ReactNode;
}

export const Info: FunctionComponent<Props> = ({ title, children }) => {
  return (
    <div className={styles.creditDataBox}>
      <div className={styles.title}>{title}</div>
      <div className={styles.list}>{children}</div>
    </div>
  );
};
