import * as math from "mathjs";
import React from "react";

import Range from "components/range";
import rangebox from "./range-box";
import styles from "./range-box.module.scss";

const RangeBox: React.FC<rangebox.props> = ({
  value,
  setValue,
  min = 0,
  max = 100,
}) => {
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputValue = +event.target.value;

  //   // Clamp the value within the range [min, max]
  //   const clampedValue = math.min(math.max(inputValue, min), max);

  //   setValue(math.fix(clampedValue, 2));
  // };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = +event.target.value;

    // Clamp the value within the range [min, max]
    const clampedValue = math.min(math.max(inputValue, min), max);

    setValue(math.fix(clampedValue, 2));
  };

  return (
    <div className={styles.rangeBox}>
      {/* @ts-ignore */}
      <Range min={min} max={max} step={1} value={value} setValue={setValue} />
      <input
        type="number"
        className={styles.input}
        value={value}
        step={1}
        min={min}
        max={max}
        onFocus={(event) => event.target.select()}
        onChange={(event) => setValue(+event.target.value)}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default RangeBox;
