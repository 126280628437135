import { Font, PDFViewer, StyleSheet } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AxiosError } from "axios";
import Loading from "components/loading";
import ResultBox from "components/result-box";

import { TypeOfProduct } from "pages/scoring/useForm";
import request from "request";
import { useSelector } from "store/hooks";
import prettierNumber from "utils/prettier-number";
import { PdfPage } from "./PdfPage";
import styles from "./result.module.scss";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

const calculateProcessingFee = (
  typeOfProduct: TypeOfProduct,
  loan_amount: string,
  sub_product_type: string,
  constants: any
) => {
  if (
    typeOfProduct === "HOUSING LOAN" &&
    sub_product_type === "Pure Buyout Only"
  ) {
    return "0";
  } else {
    return prettierNumber(
      Number(
        (
          Number(loan_amount) * Number(constants?.processing_fee_rate / 100)
        ).toFixed(2)
      )
    );
  }
};

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "10px",
  },
  section: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  tableTitle: {
    padding: "5px 10px",
    fontSize: "15px",
    color: "#000",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  result: {
    fontSize: "18px",
    fontWeight: 700,
  },
  bottom: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const Result = () => {
  const navigate = useNavigate();
  const application = useSelector((state) => state.application);
  const constants = useSelector((state) => state.constants);
  const [shapeLoading, setShapeLoading] = React.useState(true);
  const [ltv, setLtv] = useState<number | null>(null);

  React.useEffect(() => {
    if (application?.is_approved === null) navigate("/");
  }, [application, navigate]);

  React.useEffect(() => {
    if (!constants) return;

    if (application?.scoring_log) {
      const typeOfProduct = application.type_of_product;

      if (
        typeOfProduct === "HOUSING LOAN" &&
        application?.scoring_log?.property_value < 5_000_000
      ) {
        if (application?.scoring_log?.property_number === "Subsequent") {
          if (application?.nationality === "expat") {
            // @ts-ignore
            setLtv(constants?.ltv_housing_cheap_subsequent_expat);
          } else if (application?.nationality === "uae_national") {
            // @ts-ignore
            setLtv(constants?.ltv_housing_cheap_subsequent_national);
          }
        } else {
          if (application?.nationality === "expat") {
            // @ts-ignore
            setLtv(constants?.ltv_housing_cheap_first_expat);
          } else if (application?.nationality === "uae_national") {
            // @ts-ignore
            setLtv(constants?.ltv_housing_cheap_first_national);
          }
        }
      } else if (
        typeOfProduct === "HOUSING LOAN" &&
        application?.scoring_log?.property_value >= 5_000_000
      ) {
        if (application?.scoring_log?.property_number === "Subsequent") {
          if (application?.nationality === "expat") {
            // @ts-ignore
            setLtv(constants?.ltv_housing_exp_subsequent_expat);
          } else if (application?.nationality === "uae_national") {
            // @ts-ignore
            setLtv(constants?.ltv_housing_exp_subsequent_national);
          }
        } else {
          if (application?.nationality === "expat") {
            // @ts-ignore
            setLtv(constants?.ltv_housing_exp_first_expat);
          } else if (application?.nationality === "uae_national") {
            // @ts-ignore
            setLtv(constants?.ltv_housing_exp_first_national);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [constants, application?.scoring_log]);

  const [shapImage, setShapImage] = useState<string>("");
  const [shapPending, setShapPending] = useState(false);

  useEffect(() => {
    async function getImage() {
      setShapPending(true);
      try {
        const response = await request.get(
          `/applications/shap/${application?.id}`,
          {
            responseType: "blob",
          }
        );
        const data = await response.data;
        const url = URL.createObjectURL(data);
        setShapImage(url);
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          console.log(err);
        }
      } finally {
        setShapPending(false);
      }
    }
    getImage();
  }, [application?.id]);

  const parseImpacts = (text: string) => {
    // Split the text into sections using the headers as delimiters
    const sections = text.split(
      /(?=- Positive impact:|- Negative impact:|- Neutral impact:)/
    );

    const parseSection = (section: string) => {
      return section
        .split("\n")
        .slice(1)
        .map((line) => {
          const [key, value] = line.split(":").map((item) => item.trim());
          return { key, value: parseFloat(value) };
        });
    };

    const positiveImpacts = parseSection(
      sections.find((section) => section.includes("Positive impact:")) || ""
    ).filter((item) => item.key !== "");
    const negativeImpacts = parseSection(
      sections.find((section) => section.includes("Negative impact:")) || ""
    ).filter((item) => item.key !== "");
    const neutralImpacts = parseSection(
      sections.find((section) => section.includes("Neutral impact:")) || ""
    ).filter((item) => item.key !== "");

    return { positiveImpacts, negativeImpacts, neutralImpacts };
  };

  const { positiveImpacts, negativeImpacts, neutralImpacts } = parseImpacts(
    application?.scoring_log?.gpt_explanation ?? ""
  );

  if (!application || shapPending) return <></>;
  return (
    <div className={styles.result}>
      <div className={styles.fields}>
        <div className={styles.pdf}>
          <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
            <PdfPage
              ltv={ltv ?? 0}
              application={application}
              processing_fee_rate={Number(constants?.processing_fee_rate)}
              constants={constants}
              shapImage={shapImage ?? ""}
              impacts={{
                positiveImpacts: positiveImpacts,
                negativeImpacts: negativeImpacts,
                neutralImpacts: neutralImpacts,
              }}
            />
          </PDFViewer>
        </div>
        {application?.shap_file && (
          <div className={styles.creditDataBox}>
            <div className={styles.title}>Decision Shapley Values</div>
            {shapeLoading && (
              <div className={styles.loading_shap}>
                <Loading />
              </div>
            )}
            <iframe
              title="shap_link"
              srcDoc={application.shap_file}
              style={{ width: "100%" }}
              onLoad={() => setShapeLoading(false)}
            ></iframe>
            <div className={styles.gpt_text}>
              {application?.scoring_log?.gpt_explanation
                ?.split("\n")
                .map((i, index) => (
                  <React.Fragment key={index}>
                    {i} <br />
                  </React.Fragment>
                ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.resultBoxGroup}>
        <ResultBox />
      </div>
    </div>
  );
};

export default React.memo(Result, () => true);
