const prettierNumber = (num: number): string => {
  let textNum = String(num);
  let result = "";
  let extra = "";

  if (textNum.indexOf(".") !== -1) extra = "." + textNum.split(".")[1];

  textNum = textNum.split(".")[0];

  while (true) {
    for (let i = 0; i < 3 && textNum !== ""; i++) {
      result = textNum[textNum.length - 1] + result;
      textNum = textNum.slice(0, textNum.length - 1);
    }
    if (textNum === "") break;
    result = "," + result;
  }

  return result + extra;
};

export default prettierNumber;
