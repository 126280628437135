import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import global from "global";
import prettierNumber from "utils/prettier-number";

import { formatDateTime } from "utils/formatDateTime";
// @ts-ignore
import CBILogo from "./CBI__.jpg";

const calculateProcessingFee = (
  typeOfProduct: any,
  loan_amount: string,
  sub_product_type: string,
  constants: any
) => {
  if (
    typeOfProduct === "HOUSING LOAN" &&
    sub_product_type === "Pure Buyout Only"
  ) {
    return "0";
  } else if (typeOfProduct !== "HOUSING LOAN") {
    const processingFee =
      (Number(loan_amount) * Number(constants.processing_fee_rate)) / 100;
    const value =
      processingFee < 500
        ? 500
        : processingFee >= 500 && processingFee < 2500
          ? processingFee
          : processingFee >= 2500
            ? 2500
            : 0;
    return prettierNumber(Number(value?.toFixed(2)));
  } else {
    return prettierNumber(
      Number(
        (
          Number(loan_amount) * Number(constants?.processing_fee_rate / 100)
        ).toFixed(2)
      )
    );
  }
};

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf",
      fontStyle: "italic",
      fontWeight: "normal",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontStyle: "normal",
      fontWeight: "bold",
    },
  ],
});

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "10px",
  },
  section: {
    marginBottom: "4px",
    marginTop: "4px",
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  tableTitle: {
    padding: "5px 10px",
    fontSize: "15px",
    color: "#000",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  result: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: "bold",
  },
  bottom: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  purple: {
    marginTop: "-5px",
    border: "4px solid purple",
  },
  alt_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "12px",
    fontSize: "14px",
  },
  alt_header_title: {
    fontWeight: "bold",
    marginBottom: "12px",
  },
  disclaimer: {
    fontSize: "8px",
    border: "1px solid black",
    padding: "6px",
    marginTop: "12px",
    color: "#5d5d5d",
    fontFamily: "Roboto",
    fontStyle: "italic",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "6px",
    marginBottom: "6px",
  },
  footer_block: {
    fontSize: "8px",
  },
  footer_color: {
    border: "2px solid #1C164B",
    height: "2px",
    fontSize: "12px",
  },
  footer_page: {
    fontSize: "8px",
  },
  email: {
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  header_fix: {},
  shap: {
    marginTop: "24px",
  },
  shap_container: {
    border: "1px solid black",
    textAlign: "center",
    padding: "6px",
    margin: "12px 0",
  },
  gpt_container: {
    display: "flex",
    flexDirection: "row",
    gap: 4,
    fontSize: 8,
  },
  gpt_item: {
    width: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  gpt_title: {
    fontWeight: "bold",
    fontSize: 10,
  },
  due: {
    fontSize: 12,
  },
});

export const PdfHeader = ({ updated_at }: { updated_at: string }) => {
  return (
    <View fixed style={pdfStyles.header_fix}>
      <View style={pdfStyles.purple}></View>
      <View>
        <Image src={CBILogo} />
      </View>
      <View style={pdfStyles.alt_header}>
        <Text style={pdfStyles.alt_header_title}>Loan pre-approval report</Text>
        <Text>Date/Time: {formatDateTime(updated_at)}</Text>
      </View>
    </View>
  );
};

export const PdfFooter = () => {
  return (
    <View fixed>
      <View style={pdfStyles.footer}>
        <View style={pdfStyles.footer_block}>
          <Text>Issued by Commercial Bank International P.J.S.C.</Text>
          <Text>licensed by the Central Bank of UAE</Text>
        </View>
        <Text
          style={pdfStyles.footer_page}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </View>
      <View style={pdfStyles.footer_color}></View>
    </View>
  );
};

export const PdfPage = ({
  application,
  ltv,
  processing_fee_rate,
  constants,
  shapImage,
  impacts,
}: {
  application: global.application;
  ltv: number;
  processing_fee_rate: number;
  constants: global.constants | null;
  shapImage: string;
  impacts?: {
    positiveImpacts: any;
    negativeImpacts: any;
    neutralImpacts: any;
  };
}) => {
  if (!application?.scoring_log) {
    return <Document key={"document"}></Document>;
  }

  const disclaimerText = `Disclaimer: This report is a preliminary assessment and does not constitute a final loan approval. The pre-approval is subject to the verification of the information provided and other terms and conditions as determined by the bank.\n 
  The information contained in this report is confidential and is intended solely for the use of the individual(s) or entity to whom it is addressed. The pre-approval is subject to the Commercial Bank International P.J.S.C. (CBI) and is based on the data available at the time of the report. The bank reserves the right to amend, update, or withdraw the pre-approval if new information becomes available that materially affects the customer's creditworthiness or the loan's eligibility.\n
  Please note that the details herein are subject to CBI's terms and references. For more information, including the bank's terms and conditions, please visit
  our website at https://www.cbiuae.com/en/document-centre/.\n
  This report is provided as a service to our customers and does not create a legally binding commitment on the part of the bank to extend credit. The bank
  shall not be held liable for any action taken or not taken based on this report.\n
  For any questions or concerns regarding this pre-approval or the terms and conditions, please contact your Relationship Manager or the bank directly.`;

  const t = () => {
    let str = "";
    if (!application?.aecb_score_log?.is_approved) {
      str += "Agency score,";
    }

    if (!application?.dsr_log?.is_approved) {
      str += "DSR,";
    }

    if (!application?.ltv_log?.is_approved) {
      str += "LTV,";
    }

    if (
      application?.scoring_log?.is_approved !== null &&
      !application?.scoring_log?.is_approved
    ) {
      str += "Scoring,";
    }

    return str.slice(0, -1);
  };

  const calculatedProcessingFee = `${calculateProcessingFee(
    application?.type_of_product,
    String(application?.loan_amount),
    application.draft.sub_product_type,
    constants
  )}%`;

  return (
    <Document key={"document"}>
      <Page wrap style={pdfStyles.page}>
        <PdfHeader updated_at={application?.updated_at} />
        <View style={pdfStyles.disclaimer}>
          <Text>{disclaimerText}</Text>
        </View>
        <View style={{ ...pdfStyles.table, marginTop: 14 }}>
          <Text style={pdfStyles.tableTitle}>Application data</Text>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Application ID</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{application?.id}</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Type of product</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{application?.type_of_product}</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Loan amount</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{prettierNumber(application?.loan_amount)} AED</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Interest rate</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{(application?.interest_rate).toFixed(2)}%</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Customer name</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{application?.customer_name}</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Nationality</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>
                {application?.nationality === "uae_national"
                  ? "UAE National"
                  : "Expat"}
              </Text>
            </View>
          </View>
          <View style={{ ...pdfStyles.table, marginTop: 14 }}>
            <Text style={pdfStyles.tableTitle}>Loan limits</Text>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Scoring threshold</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{Number(application?.scoring_log?.threshold)}%</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>DSR threshold</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{Number(application?.dsr_log?.dsr_threshold)}%</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Agency Score Limit</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application.aecb_score_log?.aecb_score_limit}</Text>
              </View>
            </View>
            {application.type_of_product !== "PERSONAL LOAN" &&
              application?.type_of_product !== "CAR LOAN" &&
              application.ltv_log && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>LTV Limit</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{application?.ltv_log.ltv_threshold} %</Text>
                  </View>
                </View>
              )}
          </View>
          <View style={{ ...pdfStyles.table, marginTop: 14 }}>
            <Text style={pdfStyles.tableTitle}>Macro variables</Text>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Inflation rate</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log?.inflation_rate}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Manufacturing PMI</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log?.manufacturing_PMI}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>CPI</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log?.CPI}</Text>
              </View>
            </View>
          </View>

          <View style={{ ...pdfStyles.table, marginTop: 14 }}>
            <Text style={pdfStyles.tableTitle}>Loan conditions</Text>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Loan duration (months)</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {application?.scoring_log?.duration_at_disbursement_months}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Collateral</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log?.collateral}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Sub-product type</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log.sub_product_type}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Monthly installment</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {Number(
                    application?.scoring_log?.monthly_installment
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Agency Score</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application.aecb_score_log?.value.toFixed(2)}</Text>
              </View>
            </View>
            {/* <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>DBR</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{(Number(application?.dbr_log?.value) * 100).toFixed(2)} %</Text>
                  </View>
                </View>  */}
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>DSR</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{Number(application?.dsr_log?.value).toFixed(2)} %</Text>
              </View>
            </View>
            {application?.scoring_log?.is_approved !== null && (
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Scoring prediction</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application.scoring_log.prediction} %</Text>
                </View>
              </View>
            )}
            {application.type_of_product !== "PERSONAL LOAN" &&
              application?.type_of_product !== "CAR LOAN" &&
              application.ltv_log && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>LTV</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{(application.ltv_log?.value).toFixed(2)} %</Text>
                  </View>
                </View>
              )}
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Other loan monthly installment</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    Number(application?.scoring_log.auto_loan_payment)
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Credit card payment</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    Number(application?.scoring_log.credit_card_payment)
                  )}
                </Text>
              </View>
            </View>
            {/* {(application?.type_of_product === "CAR LOAN" ||
              application?.type_of_product === "PERSONAL LOAN") &&
              (Number(application?.loan_amount) *
                Number(constants?.processing_fee_rate) <=
              2500 ? (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Processing fee ({processing_fee_rate}%)</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {prettierNumber(
                        Number(
                          application?.scoring_log
                            ?.processing_fee_x_from_the_loan
                        )
                      )}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Processing fee</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>2,500</Text>
                  </View>
                </View>
              ))} */}
            {/* {application?.type_of_product === "HOUSING LOAN" && (
                  )} */}
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>
                  Processing fee
                  {`${
                    Number(calculatedProcessingFee) > 500 &&
                    Number(calculatedProcessingFee) < 2500
                      ? processing_fee_rate
                      : ""
                  }`}
                </Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{`${calculatedProcessingFee.slice(0, -1)}`}</Text>
              </View>
            </View>
            {application.type_of_product === "HOUSING LOAN" && (
              <>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Property value</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {prettierNumber(application?.scoring_log?.property_value)}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Property number</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{application?.scoring_log?.property_number}</Text>
                  </View>
                </View>
              </>
            )}
          </View>

          {/*  */}
          <View style={{ ...pdfStyles.table, marginTop: 14 }}>
            <Text style={pdfStyles.tableTitle}>Customer data</Text>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Nationality</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log?.location}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Emirates</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log?.emirates}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Gender</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log?.gender}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Family status</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log?.family_status}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Employment type</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log?.employment_sphere}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Designation</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{application?.scoring_log?.employment_level}</Text>
              </View>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Prior loans at CBI</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{application?.scoring_log?.prior_loans_at_cbi}</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Age</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{application?.scoring_log?.age}</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Monthly income</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>
                {prettierNumber(application?.scoring_log?.monthly_income_aed)}{" "}
                AED
              </Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Other monthly income</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>
                {prettierNumber(
                  // {/* @ts-ignore */}
                  application?.scoring_log?.other_income_monthly
                )}{" "}
                AED
              </Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Length of service in current company (in months)</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{application?.scoring_log?.work_experience}</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Salary transfer</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>
                {application?.scoring_log?.salary_transfer_y_n === "Y"
                  ? "Yes"
                  : "No"}
              </Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Number of loan products at CBI</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>
                {
                  application?.scoring_log
                    ?.number_of_loans_products_at_bank_with_cbi
                }
              </Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Last six months average account balance</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>
                {prettierNumber(
                  application?.scoring_log
                    ?.last_six_months_average_account_balance
                )}
              </Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Has account with the bank</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>
                {application?.scoring_log?.has_account_with_the_bank_y_n === 1
                  ? "YES"
                  : "NO"}
              </Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Number of months in UAE</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{application?.scoring_log?.number_of_years_in_uae}</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Number of cars owned</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{application?.scoring_log?.presence_of_car}</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Number of property owned</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{application?.scoring_log?.presence_of_housing}</Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>Is customer employed?</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>
                {application?.scoring_log?.is_customer_employed ? "YES" : "NO"}
              </Text>
            </View>
          </View>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>RM comment</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{application?.comment}</Text>
            </View>
          </View>

          <View style={{ ...pdfStyles.table, marginTop: 24 }}>
            <Text style={pdfStyles.tableTitle}>Stop factors</Text>
            {application?.scoring_log?.is_approved !== null && (
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Scoring</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.scoring_log?.is_approved
                      ? `Approved (${application?.scoring_log?.prediction} %)`
                      : `Rejected (${application?.scoring_log?.prediction} %)`}
                  </Text>
                </View>
              </View>
            )}
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Agency Score</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {application?.aecb_score_log?.is_approved
                    ? `Approved (${application?.aecb_score_log?.value.toFixed(
                        2
                      )})`
                    : `Rejected (${application?.aecb_score_log?.value.toFixed(
                        2
                      )})`}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>DSR</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {application?.dsr_log?.is_approved
                    ? `Approved (${application?.dsr_log?.value.toFixed(2)} %)`
                    : `Rejected (${application?.dsr_log?.value.toFixed(2)} %)`}
                </Text>
              </View>
            </View>
            {application.type_of_product === "HOUSING LOAN" &&
              application.ltv_log && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>LTV</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {application.ltv_log.is_approved
                        ? `Approved (${application?.ltv_log?.value.toFixed(
                            2
                          )} %)`
                        : `Rejected (${application?.ltv_log?.value.toFixed(
                            2
                          )} %)`}
                    </Text>
                  </View>
                </View>
              )}
          </View>
        </View>
        <PdfFooter />
      </Page>
      <Page style={pdfStyles.page}>
        <PdfHeader updated_at={application.updated_at} />
        {shapImage !== "" && (
          <View style={pdfStyles.shap_container}>
            <Text>Decision Shapley Values</Text>
            <Image style={pdfStyles.shap} src={shapImage} />
            <View style={pdfStyles.gpt_container}>
              <View
                style={{
                  ...pdfStyles.gpt_item,
                  borderRight: "1px solid black",
                }}
              >
                <Text style={pdfStyles.gpt_title}>Positive impacts</Text>
                <View>
                  {/* @ts-ignore */}
                  {impacts?.positiveImpacts.map((impact, index) => (
                    <Text key={index}>
                      {impact.key}: {impact.value}
                    </Text>
                  ))}
                </View>
              </View>
              <View
                style={{
                  ...pdfStyles.gpt_item,
                  borderRight: "1px solid black",
                }}
              >
                <Text style={pdfStyles.gpt_title}>Negative impacts</Text>
                <View>
                  {/* @ts-ignore */}
                  {impacts?.negativeImpacts.map((impact, index) => (
                    <Text key={index}>
                      {impact.key}: {impact.value}
                    </Text>
                  ))}
                </View>
              </View>
              <View style={pdfStyles.gpt_item}>
                <Text style={pdfStyles.gpt_title}>Neutral impacts</Text>
                <View>
                  {/* @ts-ignore */}
                  {impacts?.neutralImpacts.map((impact, index) => (
                    <Text key={index}>
                      {impact.key}: {impact.value}
                    </Text>
                  ))}
                </View>
              </View>
            </View>
          </View>
        )}

        <View style={pdfStyles.bottom}>
          <View>
            <Text style={pdfStyles.result}>
              Result: {application?.is_approved ? "Approved" : "Rejected"}{" "}
            </Text>
            {!application?.is_approved && (
              <Text style={pdfStyles.due}>Due to: {t()}</Text>
            )}
          </View>

          <Text style={pdfStyles.email}>
            RM: {application?.created_by.email}
          </Text>
        </View>
        <PdfFooter />
      </Page>
    </Document>
  );
};
