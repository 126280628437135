import { AxiosError } from "axios";
import React from "react";
import { useAlert } from "react-alert";

import Button from "components/button";
import Input from "components/input";
import RangeBox from "components/range-box";
import request from "request";
import { locations } from "utils/constants";
import styles from "./settings.module.scss";

const Settings = () => {
  const alert = useAlert();
  const [updatePending, setUpdatePending] = React.useState(false);
  const [loadPending, setLoadPending] = React.useState(true);

  const [scoringThreshold, setScoringThreshold] = React.useState(0);
  const [dbrThreshold, setDbrThreshold] = React.useState(0);
  const [dsrThreshold, setDsrThreshold] = React.useState(0);
  const [ltvNewCarThreshold, setLtvNewCarThreshold] = React.useState(0);
  const [ltvUsedCarThreshold, setLtvUsedCarThreshold] = React.useState(0);
  const [ltvNationalHousingThreshold, setLtvNationalHousingThreshold] =
    React.useState(0);
  const [ltvExpatHousingThreshold, setLtvExpatHousingThreshold] =
    React.useState(0);
  const [processingFeeRate, setProcessingFeeRate] = React.useState(0);
  const [CPI, setCPI] = React.useState("");
  const [inflation_rate, setInflation_rate] = React.useState("");
  const [manufacturing_PMI, setManufacturing_PMI] = React.useState("");
  const [newCountryForAecb, setNewCountryForAecb] = React.useState({
    key: "",
    value: "",
  });

  const [customAECBLimits, setCustomAECBLimits] = React.useState<
    {
      country_of_origin: typeof locations;
      aecb_value: number;
    }[]
  >([]);

  const handleSetSettings = () => {
    setUpdatePending(true);
    request
      .put("/admin/constant_values", {
        threshold: scoringThreshold,
        dbr_threshold: dbrThreshold,
        dsr_threshold: dsrThreshold,
        ltv_new_car_threshold: ltvNewCarThreshold,
        ltv_used_car_threshold: ltvUsedCarThreshold,
        ltv_national_housing_threshold: ltvNationalHousingThreshold,
        ltv_expat_housing_threshold: ltvExpatHousingThreshold,
        processing_fee_rate: processingFeeRate,
        CPI: Number(CPI),
        inflation_rate: Number(inflation_rate),
        manufacturing_PMI: Number(manufacturing_PMI),
      })
      .then(() =>
        request.put("/admin/countries_aecb", customAECBLimits).then(() => {
          alert.success("Saved!");
        })
      )
      .catch((err: AxiosError) => {
        alert.show((err.response?.data as any).detail);
      })
      .finally(() => setUpdatePending(false));
  };

  React.useEffect(() => {
    setLoadPending(true);
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((data) => {
        setScoringThreshold(Math.round(data.threshold));
        setDbrThreshold(data.dbr_threshold);
        setDsrThreshold(data.dsr_threshold);
        setLtvNewCarThreshold(data.ltv_new_car_threshold);
        setLtvUsedCarThreshold(data.ltv_used_car_threshold);
        setLtvNationalHousingThreshold(data.ltv_national_housing_threshold);
        setLtvExpatHousingThreshold(data.ltv_expat_housing_threshold);
        setProcessingFeeRate(data.processing_fee_rate);
        setCPI(data.CPI);
        setInflation_rate(data.inflation_rate);
        setManufacturing_PMI(data.manufacturing_PMI);
      })
      .finally(() => setLoadPending(false));
    request
      .get("/admin/countries_aecb")
      .then((res) => res.data)
      .then((data) => {
        setCustomAECBLimits(
          data.map((c: any) => ({
            country_of_origin: c.country_of_origin.toUpperCase(),
            aecb_value: c.aecb_value,
          }))
        );
      });
  }, []);

  if (loadPending) return <></>;
  return (
    <form className={styles.settings}>
      <div className={styles.fields}>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Macro variables</div>
          <div className={styles.fieldList}>
            <Input
              title="CPI"
              value={CPI}
              type="float"
              onInput={(value) => setCPI(value)}
              step={1}
            />
            <Input
              title="Manufacturing PMI"
              value={manufacturing_PMI}
              type="float"
              onInput={(value) => setManufacturing_PMI(value)}
              step={0.1}
            />
            <Input
              title="Inflation rate"
              value={inflation_rate}
              type="float"
              onInput={(value) => setInflation_rate(value)}
              step={0.1}
            />
          </div>
        </div>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Scoring threshold (%)</div>
          <RangeBox
            value={scoringThreshold}
            setValue={(value) => setScoringThreshold(value)}
          />
        </div>
        {/* <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>DBR threshold (%)</div>
          <RangeBox value={dbrThreshold} setValue={(value) => setDbrThreshold(value)} />
        </div> */}
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>DSR threshold (%)</div>
          <RangeBox
            value={dsrThreshold}
            setValue={(value) => setDsrThreshold(value)}
          />
        </div>
        {/* <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>LTV new car threshold (%)</div>
          <RangeBox
            value={ltvNewCarThreshold}
            setValue={(value) => setLtvNewCarThreshold(value)}
          />
        </div>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>LTV used car threshold (%)</div>
          <RangeBox
            value={ltvUsedCarThreshold}
            setValue={(value) => setLtvUsedCarThreshold(value)}
          />
        </div> */}
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>
            LTV national housing threshold (%)
          </div>
          <RangeBox
            value={ltvNationalHousingThreshold}
            setValue={(value) => setLtvNationalHousingThreshold(value)}
          />
        </div>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>
            LTV expat housing threshold (%)
          </div>
          <RangeBox
            value={ltvExpatHousingThreshold}
            setValue={(value) => setLtvExpatHousingThreshold(value)}
          />
        </div>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Processing fee rate</div>
          <RangeBox
            value={processingFeeRate}
            setValue={(value) => setProcessingFeeRate(value)}
          />
        </div>
        <div className={styles.fieldGroup}>
          <div className={styles.customAECBTitle}>
            <div className={styles.fieldTitle}>Agency Score limit</div>
            <div className={styles.customAECBRight}>
              <div className={styles.customAECBInput}>
                <Input
                  type="select"
                  hasFilterText
                  value={newCountryForAecb.key}
                  onSelect={(option) => setNewCountryForAecb(option)}
                  options={locations.map((location) => ({
                    key: location,
                    value: location,
                  }))}
                />
              </div>
              {newCountryForAecb?.key !== "" && (
                <Button
                  color="blue"
                  onClick={() => {
                    if (
                      !customAECBLimits.find(
                        (c) =>
                          String(c.country_of_origin) === newCountryForAecb.key
                      )
                    ) {
                      setCustomAECBLimits([
                        customAECBLimits[0],
                        {
                          country_of_origin:
                            newCountryForAecb.key as unknown as typeof locations,
                          aecb_value: 0,
                        },
                        ...customAECBLimits.slice(1),
                      ]);
                      setNewCountryForAecb({
                        key: "",
                        value: "",
                      });
                    }
                  }}
                >
                  Add
                </Button>
              )}
            </div>
          </div>
          {customAECBLimits.map((customAECBLimit, index) => (
            <div
              className={styles.customAECBLimit}
              key={String(customAECBLimit.country_of_origin)}
            >
              <div className={styles.customAECBLimitTitle}>
                {customAECBLimit.country_of_origin}
              </div>
              <RangeBox
                value={customAECBLimit.aecb_value}
                setValue={(value) =>
                  setCustomAECBLimits(
                    customAECBLimits.map((c) => ({
                      ...c,
                      aecb_value:
                        c.country_of_origin ===
                        customAECBLimit.country_of_origin
                          ? value
                          : c.aecb_value,
                    }))
                  )
                }
                min={300}
                max={900}
              />
              {index > 0 && (
                <div className={styles.customAECBLimitButton}>
                  <Button
                    color="red"
                    onClick={() =>
                      setCustomAECBLimits([
                        ...customAECBLimits.slice(0, index),
                        ...customAECBLimits.slice(index + 1),
                      ])
                    }
                  >
                    Remove
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.footerBox}>
          <Button pending={updatePending} onClick={handleSetSettings}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Settings;
