import { TRootState } from "../models";

const initialState = {
  get: () => {
    const initialState = localStorage.getItem("states");
    return initialState ? JSON.parse(initialState) : {};
  },
  set: (state: Partial<TRootState>) => {
    localStorage.setItem("states", JSON.stringify(state));
  },
};

export default initialState;
