import IIcon from "./icon";

const list: IIcon.svgList = {
  info: (
    <>
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill="currentColor"
      />
      <path
        d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
        fill="currentColor"
      />
      <path
        d="M12 17C11.87 17 11.74 16.97 11.62 16.92C11.5 16.87 11.39 16.8 11.29 16.71C11.2 16.61 11.13 16.51 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.87 11.03 15.74 11.08 15.62C11.13 15.5 11.2 15.39 11.29 15.29C11.39 15.2 11.5 15.13 11.62 15.08C11.86 14.98 12.14 14.98 12.38 15.08C12.5 15.13 12.61 15.2 12.71 15.29C12.8 15.39 12.87 15.5 12.92 15.62C12.97 15.74 13 15.87 13 16C13 16.13 12.97 16.26 12.92 16.38C12.87 16.51 12.8 16.61 12.71 16.71C12.61 16.8 12.5 16.87 12.38 16.92C12.26 16.97 12.13 17 12 17Z"
        fill="currentColor"
      />
    </>
  ),
  home: (
    <>
      <path
        d="M17.79 22.75H6.21C3.47 22.75 1.25 20.52 1.25 17.78V10.37C1.25 9.01 2.09 7.3 3.17 6.46L8.56 2.26C10.18 0.999997 12.77 0.939997 14.45 2.12L20.63 6.45C21.82 7.28 22.75 9.06 22.75 10.51V17.79C22.75 20.52 20.53 22.75 17.79 22.75ZM9.48 3.44L4.09 7.64C3.38 8.2 2.75 9.47 2.75 10.37V17.78C2.75 19.69 4.3 21.25 6.21 21.25H17.79C19.7 21.25 21.25 19.7 21.25 17.79V10.51C21.25 9.55 20.56 8.22 19.77 7.68L13.59 3.35C12.45 2.55 10.57 2.59 9.48 3.44Z"
        fill="currentColor"
      />
      <path
        d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z"
        fill="currentColor"
      />
    </>
  ),
  edit: (
    <>
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z"
        fill="currentColor"
      />
      <path
        d="M8.50002 17.6901C7.89002 17.6901 7.33002 17.4701 6.92002 17.0701C6.43002 16.5801 6.22002 15.8701 6.33002 15.1201L6.76002 12.1101C6.84002 11.5301 7.22002 10.7801 7.63002 10.3701L15.51 2.49006C17.5 0.500059 19.52 0.500059 21.51 2.49006C22.6 3.58006 23.09 4.69006 22.99 5.80006C22.9 6.70006 22.42 7.58006 21.51 8.48006L13.63 16.3601C13.22 16.7701 12.47 17.1501 11.89 17.2301L8.88002 17.6601C8.75002 17.6901 8.62002 17.6901 8.50002 17.6901ZM16.57 3.55006L8.69002 11.4301C8.50002 11.6201 8.28002 12.0601 8.24002 12.3201L7.81002 15.3301C7.77002 15.6201 7.83002 15.8601 7.98002 16.0101C8.13002 16.1601 8.37002 16.2201 8.66002 16.1801L11.67 15.7501C11.93 15.7101 12.38 15.4901 12.56 15.3001L20.44 7.42006C21.09 6.77006 21.43 6.19006 21.48 5.65006C21.54 5.00006 21.2 4.31006 20.44 3.54006C18.84 1.94006 17.74 2.39006 16.57 3.55006Z"
        fill="currentColor"
      />
      <path
        d="M19.85 9.83003C19.78 9.83003 19.71 9.82003 19.65 9.80003C17.02 9.06003 14.93 6.97003 14.19 4.34003C14.08 3.94003 14.31 3.53003 14.71 3.41003C15.11 3.30003 15.52 3.53003 15.63 3.93003C16.23 6.06003 17.92 7.75003 20.05 8.35003C20.45 8.46003 20.68 8.88003 20.57 9.28003C20.48 9.62003 20.18 9.83003 19.85 9.83003Z"
        fill="currentColor"
      />
    </>
  ),
  messageText: (
    <>
      <path
        d="M12 22.81C11.31 22.81 10.66 22.46 10.2 21.85L8.7 19.85C8.67 19.81 8.55 19.76 8.5 19.75H8C3.83 19.75 1.25 18.62 1.25 13V8C1.25 3.58 3.58 1.25 8 1.25H16C20.42 1.25 22.75 3.58 22.75 8V13C22.75 17.42 20.42 19.75 16 19.75H15.5C15.42 19.75 15.35 19.79 15.3 19.85L13.8 21.85C13.34 22.46 12.69 22.81 12 22.81ZM8 2.75C4.42 2.75 2.75 4.42 2.75 8V13C2.75 17.52 4.3 18.25 8 18.25H8.5C9.01 18.25 9.59 18.54 9.9 18.95L11.4 20.95C11.75 21.41 12.25 21.41 12.6 20.95L14.1 18.95C14.43 18.51 14.95 18.25 15.5 18.25H16C19.58 18.25 21.25 16.58 21.25 13V8C21.25 4.42 19.58 2.75 16 2.75H8Z"
        fill="currentColor"
      />
      <path
        d="M17 8.75H7C6.59 8.75 6.25 8.41 6.25 8C6.25 7.59 6.59 7.25 7 7.25H17C17.41 7.25 17.75 7.59 17.75 8C17.75 8.41 17.41 8.75 17 8.75Z"
        fill="currentColor"
      />
      <path
        d="M13 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H13C13.41 12.25 13.75 12.59 13.75 13C13.75 13.41 13.41 13.75 13 13.75Z"
        fill="currentColor"
      />
    </>
  ),
  homeFilled: (
    <>
      <path
        opacity="0.4"
        d="M20.04 6.82L14.28 2.79C12.71 1.69 10.3 1.75 8.79001 2.92L3.78001 6.83C2.78001 7.61 1.99001 9.21 1.99001 10.47V17.37C1.99001 19.92 4.06001 22 6.61001 22H17.39C19.94 22 22.01 19.93 22.01 17.38V10.6C22.01 9.25 21.14 7.59 20.04 6.82Z"
        fill="currentColor"
      />
      <path
        d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z"
        fill="currentColor"
      />
    </>
  ),
  receiptSearch: (
    <>
      <path
        d="M10.54 21.94C10.31 21.94 10.09 21.84 9.94003 21.64L8.93002 20.29C8.72002 20.01 8.44005 19.85 8.14005 19.83C7.84005 19.81 7.54002 19.95 7.30002 20.2C5.85002 21.75 4.75003 21.62 4.22003 21.42C3.68003 21.21 2.77002 20.52 2.77002 18.3V7.04001C2.77002 2.60001 4.05002 1.25 8.24002 1.25H15.79C19.98 1.25 21.26 2.60001 21.26 7.04001V11.3C21.26 11.71 20.92 12.05 20.51 12.05C20.1 12.05 19.76 11.71 19.76 11.3V7.04001C19.76 3.43001 19.13 2.75 15.79 2.75H8.24002C4.90002 2.75 4.27002 3.43001 4.27002 7.04001V18.3C4.27002 19.35 4.53002 19.93 4.77002 20.02C4.95002 20.09 5.44004 19.99 6.20004 19.18C6.75004 18.6 7.47003 18.29 8.22003 18.33C8.96003 18.37 9.66004 18.76 10.13 19.39L11.15 20.74C11.4 21.07 11.33 21.54 11 21.79C10.85 21.9 10.69 21.94 10.54 21.94Z"
        fill="currentColor"
      />
      <path
        d="M18.2 22.15C16.02 22.15 14.25 20.38 14.25 18.2C14.25 16.02 16.02 14.25 18.2 14.25C20.38 14.25 22.15 16.02 22.15 18.2C22.15 20.38 20.38 22.15 18.2 22.15ZM18.2 15.75C16.85 15.75 15.75 16.85 15.75 18.2C15.75 19.55 16.85 20.65 18.2 20.65C19.55 20.65 20.65 19.55 20.65 18.2C20.65 16.85 19.55 15.75 18.2 15.75Z"
        fill="currentColor"
      />
      <path
        d="M21.9999 22.75C21.8099 22.75 21.6199 22.68 21.4699 22.53L20.4699 21.53C20.1799 21.24 20.1799 20.76 20.4699 20.47C20.7599 20.18 21.2399 20.18 21.5299 20.47L22.5299 21.47C22.8199 21.76 22.8199 22.24 22.5299 22.53C22.3799 22.68 22.1899 22.75 21.9999 22.75Z"
        fill="currentColor"
      />
      <path
        d="M16 7.75H8C7.59 7.75 7.25 7.41 7.25 7C7.25 6.59 7.59 6.25 8 6.25H16C16.41 6.25 16.75 6.59 16.75 7C16.75 7.41 16.41 7.75 16 7.75Z"
        fill="currentColor"
      />
      <path
        d="M15 11.75H9C8.59 11.75 8.25 11.41 8.25 11C8.25 10.59 8.59 10.25 9 10.25H15C15.41 10.25 15.75 10.59 15.75 11C15.75 11.41 15.41 11.75 15 11.75Z"
        fill="currentColor"
      />
    </>
  ),
  receiptSearchFilled: (
    <>
      <path
        opacity="0.4"
        d="M6.73001 19.7C7.55001 18.82 8.79999 18.89 9.51999 19.85L10.53 21.2C11.34 22.27 12.65 22.27 13.46 21.2L14.47 19.85C15.19 18.89 16.44 18.82 17.26 19.7C19.04 21.6 20.49 20.97 20.49 18.31V7.04999C20.49 3.01999 19.55 2.01001 15.77 2.01001H8.20999C4.42999 2.01001 3.48999 3.01999 3.48999 7.04999V18.31C3.49999 20.97 4.96001 21.59 6.73001 19.7Z"
        fill="currentColor"
      />
      <path
        d="M15.6599 13.47L14.9199 12.73C15.2999 12.15 15.5299 11.45 15.5299 10.7C15.5299 8.66001 13.8699 7 11.8299 7C9.78986 7 8.12988 8.66001 8.12988 10.7C8.12988 12.74 9.78986 14.4 11.8299 14.4C12.5799 14.4 13.2799 14.17 13.8599 13.79L14.5999 14.53C14.7499 14.68 14.9399 14.75 15.1299 14.75C15.3199 14.75 15.5099 14.68 15.6599 14.53C15.9499 14.24 15.9499 13.76 15.6599 13.47Z"
        fill="currentColor"
      />
    </>
  ),
  filterTick: (
    <>
      <path
        d="M16.8799 20.25C15.8199 20.25 14.7899 19.94 13.9099 19.35C13.2199 18.93 12.6199 18.31 12.1799 17.58C11.6599 16.73 11.3799 15.75 11.3799 14.75C11.3799 11.72 13.8499 9.25 16.8799 9.25C17.2999 9.25 17.7099 9.30001 18.0999 9.39001C20.5799 9.94001 22.3799 12.19 22.3799 14.75C22.3799 15.76 22.1099 16.74 21.5899 17.59C20.6099 19.23 18.7999 20.25 16.8799 20.25ZM16.8799 10.75C14.6699 10.75 12.8799 12.54 12.8799 14.75C12.8799 15.48 13.0799 16.19 13.4599 16.81C13.7799 17.34 14.2099 17.78 14.7099 18.09C15.3699 18.53 16.1099 18.75 16.8799 18.75C18.2799 18.75 19.5899 18.01 20.3099 16.81C20.6899 16.19 20.8799 15.48 20.8799 14.75C20.8799 12.89 19.5699 11.25 17.7599 10.85C17.4699 10.78 17.1799 10.75 16.8799 10.75Z"
        fill="currentColor"
      />
      <path
        d="M16.1997 16.67C16.0097 16.67 15.8197 16.6 15.6697 16.45L14.4997 15.28C14.2097 14.99 14.2097 14.51 14.4997 14.22C14.7897 13.93 15.2697 13.93 15.5597 14.22L16.2197 14.88L18.2297 13.03C18.5397 12.75 19.0097 12.77 19.2897 13.07C19.5697 13.37 19.5497 13.85 19.2497 14.13L16.7097 16.47C16.5697 16.6 16.3797 16.67 16.1997 16.67Z"
        fill="currentColor"
      />
      <path
        d="M10.9298 22.75C10.4498 22.75 9.96979 22.63 9.53979 22.39C8.64979 21.89 8.11981 20.99 8.11981 19.98V14.63C8.11981 14.12 7.78979 13.37 7.46979 12.98L3.6698 8.98999C3.0398 8.33999 2.5498 7.25001 2.5498 6.45001V4.12C2.5498 2.51 3.76982 1.25 5.31982 1.25H18.6598C20.1898 1.25 21.4298 2.48999 21.4298 4.01999V6.23999C21.4298 7.28999 20.8098 8.47 20.1998 9.09L18.3998 10.68C18.2198 10.84 17.9698 10.9 17.7298 10.85C17.4598 10.78 17.1698 10.75 16.8698 10.75C14.6598 10.75 12.8698 12.54 12.8698 14.75C12.8698 15.48 13.0698 16.19 13.4498 16.81C13.7698 17.34 14.1998 17.78 14.6998 18.09C14.9198 18.23 15.0598 18.47 15.0598 18.73V19.07C15.0598 19.86 14.5798 20.97 13.7798 21.44L12.3998 22.33C11.9598 22.61 11.4398 22.75 10.9298 22.75ZM5.32983 2.75C4.61983 2.75 4.05981 3.35 4.05981 4.12V6.45001C4.05981 6.81001 4.35983 7.54 4.75983 7.94L8.6098 11.99C9.1198 12.62 9.62982 13.68 9.62982 14.63V19.98C9.62982 20.64 10.0898 20.97 10.2798 21.08C10.7098 21.32 11.2198 21.31 11.6098 21.07L13.0098 20.17C13.2798 20.01 13.5498 19.5 13.5698 19.14C13.0198 18.73 12.5398 18.2 12.1798 17.6C11.6598 16.75 11.3798 15.77 11.3798 14.77C11.3798 11.74 13.8498 9.26999 16.8798 9.26999C17.1598 9.26999 17.4398 9.28999 17.6998 9.32999L19.1798 8.01999C19.5198 7.66999 19.9398 6.84 19.9398 6.25V4.03C19.9398 3.33 19.3698 2.76001 18.6698 2.76001H5.32983V2.75Z"
        fill="currentColor"
      />
    </>
  ),
  filterTickFilled: (
    <>
      <path
        d="M17.9199 10.12C17.5899 10.04 17.2399 10 16.8799 10C14.2599 10 12.1299 12.13 12.1299 14.75C12.1299 15.64 12.3799 16.48 12.8199 17.2C13.1899 17.82 13.6999 18.35 14.3199 18.73C15.0599 19.22 15.9399 19.5 16.8799 19.5C18.6199 19.5 20.1299 18.57 20.9499 17.2C21.3899 16.48 21.6299 15.64 21.6299 14.75C21.6299 12.49 20.0499 10.59 17.9199 10.12ZM19.2499 14.13L16.7099 16.47C16.5699 16.6 16.3799 16.67 16.1999 16.67C16.0099 16.67 15.8199 16.6 15.6699 16.45L14.4999 15.28C14.2099 14.99 14.2099 14.51 14.4999 14.22C14.7899 13.93 15.2699 13.93 15.5599 14.22L16.2198 14.88L18.2299 13.03C18.5399 12.75 19.0099 12.77 19.2899 13.07C19.5699 13.38 19.5499 13.85 19.2499 14.13Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M5.40991 2H18.58C19.68 2 20.58 2.90999 20.58 4.01999V6.23999C20.58 7.04999 20.08 8.06 19.58 8.56L15.2899 12.4C14.6899 12.91 14.2899 13.92 14.2899 14.72V19.06C14.2899 19.67 13.89 20.47 13.39 20.78L11.9899 21.69C10.6899 22.5 8.89996 21.59 8.89996 19.97V14.62C8.89996 13.91 8.49991 13 8.09991 12.5L4.30994 8.45999C3.80994 7.94999 3.40991 7.05 3.40991 6.44V4.12C3.41991 2.91 4.31991 2 5.40991 2Z"
        fill="currentColor"
      />
    </>
  ),
  closeFilled: (
    <>
      <path
        opacity="0.4"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="currentColor"
      />
      <path
        d="M13.06 12L15.36 9.69998C15.65 9.40998 15.65 8.92999 15.36 8.63999C15.07 8.34999 14.59 8.34999 14.3 8.63999L12 10.94L9.7 8.63999C9.41 8.34999 8.93 8.34999 8.64 8.63999C8.35 8.92999 8.35 9.40998 8.64 9.69998L10.94 12L8.64 14.3C8.35 14.59 8.35 15.07 8.64 15.36C8.79 15.51 8.98 15.58 9.17 15.58C9.36 15.58 9.55 15.51 9.7 15.36L12 13.06L14.3 15.36C14.45 15.51 14.64 15.58 14.83 15.58C15.02 15.58 15.21 15.51 15.36 15.36C15.65 15.07 15.65 14.59 15.36 14.3L13.06 12Z"
        fill="currentColor"
      />
    </>
  ),
  arrowRight: (
    <>
      <path d="M8.59 7.41L13.17 12L8.59 16.59L10 18L16 12L10 6L8.59 7.41Z" fill="currentColor" />
    </>
  ),
  arrowLeft: (
    <>
      <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill="currentColor" />
    </>
  ),
  longArrowLeft: (
    <>
      <path
        d="M9.56994 18.82C9.37994 18.82 9.18994 18.75 9.03994 18.6L2.96994 12.53C2.67994 12.24 2.67994 11.76 2.96994 11.47L9.03994 5.4C9.32994 5.11 9.80994 5.11 10.0999 5.4C10.3899 5.69 10.3899 6.17 10.0999 6.46L4.55994 12L10.0999 17.54C10.3899 17.83 10.3899 18.31 10.0999 18.6C9.95994 18.75 9.75994 18.82 9.56994 18.82Z"
        fill="currentColor"
      />
      <path
        d="M20.4999 12.75H3.66992C3.25992 12.75 2.91992 12.41 2.91992 12C2.91992 11.59 3.25992 11.25 3.66992 11.25H20.4999C20.9099 11.25 21.2499 11.59 21.2499 12C21.2499 12.41 20.9099 12.75 20.4999 12.75Z"
        fill="currentColor"
      />
    </>
  ),
  verify: (
    <>
      <path
        d="M10.7901 15.17C10.5901 15.17 10.4001 15.09 10.2601 14.95L7.84006 12.53C7.55006 12.24 7.55006 11.76 7.84006 11.47C8.13006 11.18 8.61006 11.18 8.90006 11.47L10.7901 13.36L15.0901 9.06003C15.3801 8.77003 15.8601 8.77003 16.1501 9.06003C16.4401 9.35003 16.4401 9.83003 16.1501 10.12L11.3201 14.95C11.1801 15.09 10.9901 15.17 10.7901 15.17Z"
        fill="currentColor"
      />
      <path
        d="M11.9999 22.75C11.3699 22.75 10.7399 22.54 10.2499 22.12L8.66988 20.76C8.50988 20.62 8.10988 20.48 7.89988 20.48H6.17988C4.69988 20.48 3.49988 19.28 3.49988 17.8V16.09C3.49988 15.88 3.35988 15.49 3.21988 15.33L1.86988 13.74C1.04988 12.77 1.04988 11.24 1.86988 10.27L3.21988 8.68C3.35988 8.52 3.49988 8.13 3.49988 7.92V6.2C3.49988 4.72 4.69988 3.52 6.17988 3.52H7.90988C8.11988 3.52 8.51988 3.37 8.67988 3.24L10.2599 1.88C11.2399 1.04 12.7699 1.04 13.7499 1.88L15.3299 3.24C15.4899 3.38 15.8899 3.52 16.0999 3.52H17.7999C19.2799 3.52 20.4799 4.72 20.4799 6.2V7.9C20.4799 8.11 20.6299 8.51 20.7699 8.67L22.1299 10.25C22.9699 11.23 22.9699 12.76 22.1299 13.74L20.7699 15.32C20.6299 15.48 20.4799 15.88 20.4799 16.09V17.79C20.4799 19.27 19.2799 20.47 17.7999 20.47H16.0999C15.8899 20.47 15.4899 20.62 15.3299 20.75L13.7499 22.11C13.2599 22.54 12.6299 22.75 11.9999 22.75ZM6.17988 5.02C5.52988 5.02 4.99988 5.55 4.99988 6.2V7.91C4.99988 8.48 4.72988 9.21 4.35988 9.64L3.00988 11.23C2.65988 11.64 2.65988 12.35 3.00988 12.76L4.35988 14.35C4.72988 14.79 4.99988 15.51 4.99988 16.08V17.79C4.99988 18.44 5.52988 18.97 6.17988 18.97H7.90988C8.48988 18.97 9.21988 19.24 9.65988 19.62L11.2399 20.98C11.6499 21.33 12.3699 21.33 12.7799 20.98L14.3599 19.62C14.7999 19.25 15.5299 18.97 16.1099 18.97H17.8099C18.4599 18.97 18.9899 18.44 18.9899 17.79V16.09C18.9899 15.51 19.2599 14.78 19.6399 14.34L20.9999 12.76C21.3499 12.35 21.3499 11.63 20.9999 11.22L19.6399 9.64C19.2599 9.2 18.9899 8.47 18.9899 7.89V6.2C18.9899 5.55 18.4599 5.02 17.8099 5.02H16.1099C15.5299 5.02 14.7999 4.75 14.3599 4.37L12.7799 3.01C12.3699 2.66 11.6499 2.66 11.2399 3.01L9.65988 4.38C9.21988 4.75 8.47988 5.02 7.90988 5.02H6.17988Z"
        fill="currentColor"
      />
    </>
  ),
  verifyFill: (
    <>
      <path
        opacity="0.4"
        d="M10.7499 2.45001C11.4399 1.86001 12.5699 1.86001 13.2699 2.45001L14.8499 3.81001C15.1499 4.07001 15.7099 4.28001 16.1099 4.28001H17.8099C18.8699 4.28001 19.7399 5.15001 19.7399 6.21001V7.91001C19.7399 8.30001 19.9499 8.87001 20.2099 9.17001L21.5699 10.75C22.1599 11.44 22.1599 12.57 21.5699 13.27L20.2099 14.85C19.9499 15.15 19.7399 15.71 19.7399 16.11V17.81C19.7399 18.87 18.8699 19.74 17.8099 19.74H16.1099C15.7199 19.74 15.1499 19.95 14.8499 20.21L13.2699 21.57C12.5799 22.16 11.4499 22.16 10.7499 21.57L9.16988 20.21C8.86988 19.95 8.30988 19.74 7.90988 19.74H6.17988C5.11988 19.74 4.24988 18.87 4.24988 17.81V16.1C4.24988 15.71 4.03988 15.15 3.78988 14.85L2.43988 13.26C1.85988 12.57 1.85988 11.45 2.43988 10.76L3.78988 9.17001C4.03988 8.87001 4.24988 8.31001 4.24988 7.92001V6.20001C4.24988 5.14001 5.11988 4.27001 6.17988 4.27001H7.90988C8.29988 4.27001 8.86988 4.06001 9.16988 3.80001L10.7499 2.45001Z"
        fill="currentColor"
      />
      <path
        d="M10.7901 15.17C10.5901 15.17 10.4001 15.09 10.2601 14.95L7.84006 12.53C7.55006 12.24 7.55006 11.76 7.84006 11.47C8.13006 11.18 8.61006 11.18 8.90006 11.47L10.7901 13.36L15.0901 9.06003C15.3801 8.77003 15.8601 8.77003 16.1501 9.06003C16.4401 9.35003 16.4401 9.83003 16.1501 10.12L11.3201 14.95C11.1801 15.09 10.9901 15.17 10.7901 15.17Z"
        fill="currentColor"
      />
    </>
  ),
  documentFilter: (
    <>
      <path
        d="M7.08002 16.1C6.72002 16.1 6.36003 16.01 6.03003 15.82C5.36003 15.44 4.96002 14.76 4.96002 14V10.43C4.96002 10.17 4.79002 9.73001 4.58002 9.48001L2.06 6.82999C1.59 6.36999 1.25 5.56999 1.25 4.95999V3.41C1.25 2.2 2.16998 1.23999 3.34998 1.23999H12.25C13.41 1.23999 14.35 2.18 14.35 3.34V4.82001C14.35 5.60001 13.9 6.45999 13.46 6.89999L10.53 9.48999C10.3 9.67999 10.11 10.14 10.11 10.48V13.38C10.11 14.07 9.69001 14.84 9.14001 15.17L8.21997 15.77C7.85997 15.99 7.47002 16.1 7.08002 16.1ZM3.34998 2.75C3.00998 2.75 2.75 3.04001 2.75 3.42001V4.97C2.75 5.14 2.92001 5.57001 3.14001 5.79001L5.71997 8.5C6.09997 8.97 6.46997 9.73999 6.46997 10.43V14C6.46997 14.3 6.66003 14.45 6.78003 14.51C6.94003 14.6 7.19998 14.65 7.41998 14.51L8.34998 13.91C8.46998 13.84 8.60999 13.56 8.60999 13.39V10.49C8.60999 9.69999 8.99999 8.82999 9.54999 8.35999L12.43 5.81C12.61 5.63 12.85 5.14001 12.85 4.82001V3.34C12.85 3.01 12.58 2.73999 12.25 2.73999H3.34998V2.75Z"
        fill="currentColor"
      />
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V12C1.25 11.59 1.59 11.25 2 11.25C2.41 11.25 2.75 11.59 2.75 12V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V8.99999C21.25 6.09999 20.57 4.41998 19.04 3.54998C18.88 3.45998 18.35 3.22 16.83 2.98C16.42 2.92 16.14 2.52998 16.21 2.11998C16.28 1.70998 16.67 1.42999 17.07 1.49999C18.38 1.70999 19.27 1.94998 19.78 2.23998C21.8 3.38998 22.75 5.53999 22.75 8.99999V15C22.75 20.43 20.43 22.75 15 22.75Z"
        fill="currentColor"
      />
      <path
        d="M18 13.75H13C12.59 13.75 12.25 13.41 12.25 13C12.25 12.59 12.59 12.25 13 12.25H18C18.41 12.25 18.75 12.59 18.75 13C18.75 13.41 18.41 13.75 18 13.75Z"
        fill="currentColor"
      />
      <path
        d="M18 17.75H11C10.59 17.75 10.25 17.41 10.25 17C10.25 16.59 10.59 16.25 11 16.25H18C18.41 16.25 18.75 16.59 18.75 17C18.75 17.41 18.41 17.75 18 17.75Z"
        fill="currentColor"
      />
    </>
  ),
  documentFilterFilled: (
    <>
      <path
        d="M3.22998 1H11.36C12.04 1 12.59 1.56 12.59 2.25V3.62C12.59 4.12 12.28 4.73999 11.97 5.04999L9.32001 7.42001C8.95001 7.73001 8.70001 8.36001 8.70001 8.85001V11.53C8.70001 11.9 8.45002 12.4 8.15002 12.59L7.28998 13.15C6.48998 13.65 5.38 13.09 5.38 12.09V8.78C5.38 8.34 5.13001 7.78 4.89001 7.47L2.54999 4.97C2.23999 4.66 2 4.1 2 3.72V2.29001C2 1.56001 2.54998 1 3.22998 1Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M17 2H14.6C14.32 2 14.1 2.22 14.1 2.5C14.1 2.98 14.1 3.62 14.1 3.62C14.1 4.61 13.57 5.57999 13.05 6.10999L10.33 8.54001C10.3 8.61001 10.25 8.71001 10.22 8.79001V11.54C10.22 12.45 9.68 13.44 8.94 13.89L8.12 14.42C7.66 14.71 7.15001 14.85 6.64001 14.85C6.18001 14.85 5.71999 14.73 5.29999 14.5C4.64999 14.14 4.2 13.55 4 12.87V10.21C4 10.08 3.94998 9.94999 3.84998 9.85999L2.84998 8.85999C2.52998 8.54999 2 8.76999 2 9.20999V17C2 19.76 4.24 22 7 22H17C19.76 22 22 19.76 22 17V7C22 4.24 19.76 2 17 2Z"
        fill="currentColor"
      />
      <path
        d="M18 13.75H13C12.59 13.75 12.25 13.41 12.25 13C12.25 12.59 12.59 12.25 13 12.25H18C18.41 12.25 18.75 12.59 18.75 13C18.75 13.41 18.41 13.75 18 13.75Z"
        fill="currentColor"
      />
      <path
        d="M18 17.75H11C10.59 17.75 10.25 17.41 10.25 17C10.25 16.59 10.59 16.25 11 16.25H18C18.41 16.25 18.75 16.59 18.75 17C18.75 17.41 18.41 17.75 18 17.75Z"
        fill="currentColor"
      />
    </>
  ),
  logout: (
    <>
      <path
        d="M15.24 22.27H15.11C10.67 22.27 8.53002 20.52 8.16002 16.6C8.12002 16.19 8.42002 15.82 8.84002 15.78C9.24002 15.74 9.62002 16.05 9.66002 16.46C9.95002 19.6 11.43 20.77 15.12 20.77H15.25C19.32 20.77 20.76 19.33 20.76 15.26V8.74C20.76 4.67 19.32 3.23 15.25 3.23H15.12C11.41 3.23 9.93002 4.42 9.66002 7.62C9.61002 8.03 9.26002 8.34 8.84002 8.3C8.42002 8.27 8.12001 7.9 8.15001 7.49C8.49001 3.51 10.64 1.73 15.11 1.73H15.24C20.15 1.73 22.25 3.83 22.25 8.74V15.26C22.25 20.17 20.15 22.27 15.24 22.27Z"
        fill="currentColor"
      />
      <path
        d="M15 12.75H3.62C3.21 12.75 2.87 12.41 2.87 12C2.87 11.59 3.21 11.25 3.62 11.25H15C15.41 11.25 15.75 11.59 15.75 12C15.75 12.41 15.41 12.75 15 12.75Z"
        fill="currentColor"
      />
      <path
        d="M5.85 16.1C5.66 16.1 5.47 16.03 5.32 15.88L1.97 12.53C1.68 12.24 1.68 11.76 1.97 11.47L5.32 8.12C5.61 7.83 6.09 7.83 6.38 8.12C6.67 8.41 6.67 8.89 6.38 9.18L3.56 12L6.38 14.82C6.67 15.11 6.67 15.59 6.38 15.88C6.24 16.03 6.04 16.1 5.85 16.1Z"
        fill="currentColor"
      />
    </>
  ),
  arrowDown: (
    <>
      <path
        d="M12 16.8C11.3 16.8 10.6 16.53 10.07 16L3.55002 9.48C3.26002 9.19 3.26002 8.71 3.55002 8.42C3.84002 8.13 4.32002 8.13 4.61002 8.42L11.13 14.94C11.61 15.42 12.39 15.42 12.87 14.94L19.39 8.42C19.68 8.13 20.16 8.13 20.45 8.42C20.74 8.71 20.74 9.19 20.45 9.48L13.93 16C13.4 16.53 12.7 16.8 12 16.8Z"
        fill="currentColor"
      />
    </>
  ),
  arrowUp: (
    <>
      <path
        d="M12 7.2C12.7 7.2 13.4 7.47 13.93 8L20.45 14.52C20.74 14.81 20.74 15.29 20.45 15.58C20.16 15.87 19.68 15.87 19.39 15.58L12.87 9.06C12.39 8.58 11.61 8.58 11.13 9.06L4.61001 15.58C4.32001 15.87 3.84001 15.87 3.55001 15.58C3.26001 15.29 3.26001 14.81 3.55001 14.52L10.07 8C10.6 7.47 11.3 7.2 12 7.2Z"
        fill="currentColor"
      />
    </>
  ),
  tickCircle: (
    <>
      <path
        opacity="0.4"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="currentColor"
      />
      <path
        d="M10.58 15.58C10.38 15.58 10.19 15.5 10.05 15.36L7.22 12.53C6.93 12.24 6.93 11.76 7.22 11.47C7.51 11.18 7.99 11.18 8.28 11.47L10.58 13.77L15.72 8.63001C16.01 8.34001 16.49 8.34001 16.78 8.63001C17.07 8.92001 17.07 9.40001 16.78 9.69001L11.11 15.36C10.97 15.5 10.78 15.58 10.58 15.58Z"
        fill="currentColor"
      />
    </>
  ),
  calendar: (
    <>
      <path
        d="M8 5.75C7.59 5.75 7.25 5.41 7.25 5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V5C8.75 5.41 8.41 5.75 8 5.75Z"
        fill="currentColor"
      />
      <path
        d="M16 5.75C15.59 5.75 15.25 5.41 15.25 5V2C15.25 1.59 15.59 1.25 16 1.25C16.41 1.25 16.75 1.59 16.75 2V5C16.75 5.41 16.41 5.75 16 5.75Z"
        fill="currentColor"
      />
      <path
        d="M8.5 14.5C8.37 14.5 8.24 14.47 8.12 14.42C7.99 14.37 7.89 14.3 7.79 14.21C7.61 14.02 7.5 13.77 7.5 13.5C7.5 13.37 7.53 13.24 7.58 13.12C7.63 13 7.7 12.89 7.79 12.79C7.89 12.7 7.99 12.63 8.12 12.58C8.48 12.43 8.93 12.51 9.21 12.79C9.39 12.98 9.5 13.24 9.5 13.5C9.5 13.56 9.49 13.63 9.48 13.7C9.47 13.76 9.45 13.82 9.42 13.88C9.4 13.94 9.37 14 9.33 14.06C9.3 14.11 9.25 14.16 9.21 14.21C9.02 14.39 8.76 14.5 8.5 14.5Z"
        fill="currentColor"
      />
      <path
        d="M12 14.5C11.87 14.5 11.74 14.47 11.62 14.42C11.49 14.37 11.39 14.3 11.29 14.21C11.11 14.02 11 13.77 11 13.5C11 13.37 11.03 13.24 11.08 13.12C11.13 13 11.2 12.89 11.29 12.79C11.39 12.7 11.49 12.63 11.62 12.58C11.98 12.42 12.43 12.51 12.71 12.79C12.89 12.98 13 13.24 13 13.5C13 13.56 12.99 13.63 12.98 13.7C12.97 13.76 12.95 13.82 12.92 13.88C12.9 13.94 12.87 14 12.83 14.06C12.8 14.11 12.75 14.16 12.71 14.21C12.52 14.39 12.26 14.5 12 14.5Z"
        fill="currentColor"
      />
      <path
        d="M15.5 14.5C15.37 14.5 15.24 14.47 15.12 14.42C14.99 14.37 14.89 14.3 14.79 14.21C14.75 14.16 14.71 14.11 14.67 14.06C14.63 14 14.6 13.94 14.58 13.88C14.55 13.82 14.53 13.76 14.52 13.7C14.51 13.63 14.5 13.56 14.5 13.5C14.5 13.24 14.61 12.98 14.79 12.79C14.89 12.7 14.99 12.63 15.12 12.58C15.49 12.42 15.93 12.51 16.21 12.79C16.39 12.98 16.5 13.24 16.5 13.5C16.5 13.56 16.49 13.63 16.48 13.7C16.47 13.76 16.45 13.82 16.42 13.88C16.4 13.94 16.37 14 16.33 14.06C16.3 14.11 16.25 14.16 16.21 14.21C16.02 14.39 15.76 14.5 15.5 14.5Z"
        fill="currentColor"
      />
      <path
        d="M8.5 18C8.37 18 8.24 17.97 8.12 17.92C8 17.87 7.89 17.8 7.79 17.71C7.61 17.52 7.5 17.26 7.5 17C7.5 16.87 7.53 16.74 7.58 16.62C7.63 16.49 7.7 16.38 7.79 16.29C8.16 15.92 8.84 15.92 9.21 16.29C9.39 16.48 9.5 16.74 9.5 17C9.5 17.26 9.39 17.52 9.21 17.71C9.02 17.89 8.76 18 8.5 18Z"
        fill="currentColor"
      />
      <path
        d="M12 18C11.74 18 11.48 17.89 11.29 17.71C11.11 17.52 11 17.26 11 17C11 16.87 11.03 16.74 11.08 16.62C11.13 16.49 11.2 16.38 11.29 16.29C11.66 15.92 12.34 15.92 12.71 16.29C12.8 16.38 12.87 16.49 12.92 16.62C12.97 16.74 13 16.87 13 17C13 17.26 12.89 17.52 12.71 17.71C12.52 17.89 12.26 18 12 18Z"
        fill="currentColor"
      />
      <path
        d="M15.5 18C15.24 18 14.98 17.89 14.79 17.71C14.7 17.62 14.63 17.51 14.58 17.38C14.53 17.26 14.5 17.13 14.5 17C14.5 16.87 14.53 16.74 14.58 16.62C14.63 16.49 14.7 16.38 14.79 16.29C15.02 16.06 15.37 15.95 15.69 16.02C15.76 16.03 15.82 16.05 15.88 16.08C15.94 16.1 16 16.13 16.06 16.17C16.11 16.2 16.16 16.25 16.21 16.29C16.39 16.48 16.5 16.74 16.5 17C16.5 17.26 16.39 17.52 16.21 17.71C16.02 17.89 15.76 18 15.5 18Z"
        fill="currentColor"
      />
      <path
        d="M20.5 9.83997H3.5C3.09 9.83997 2.75 9.49997 2.75 9.08997C2.75 8.67997 3.09 8.33997 3.5 8.33997H20.5C20.91 8.33997 21.25 8.67997 21.25 9.08997C21.25 9.49997 20.91 9.83997 20.5 9.83997Z"
        fill="currentColor"
      />
      <path
        d="M16 22.75H8C4.35 22.75 2.25 20.65 2.25 17V8.5C2.25 4.85 4.35 2.75 8 2.75H16C19.65 2.75 21.75 4.85 21.75 8.5V17C21.75 20.65 19.65 22.75 16 22.75ZM8 4.25C5.14 4.25 3.75 5.64 3.75 8.5V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V8.5C20.25 5.64 18.86 4.25 16 4.25H8Z"
        fill="currentColor"
      />
    </>
  ),
  visibilityOff: (
    <>
      <path
        d="M12 6.00001C15.79 6.00001 19.17 8.13001 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.11001 17 4.00001 12 4.00001C10.73 4.00001 9.51 4.20001 8.36 4.57001L10.01 6.22001C10.66 6.09001 11.32 6.00001 12 6.00001ZM10.93 7.14001L13 9.21001C13.57 9.46001 14.03 9.92001 14.28 10.49L16.35 12.56C16.43 12.22 16.49 11.86 16.49 11.49C16.5 9.01001 14.48 7.00001 12 7.00001C11.63 7.00001 11.28 7.05001 10.93 7.14001ZM2.01 3.87001L4.69 6.55001C3.06 7.83001 1.77 9.53001 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.98 18.71 16.32 18.18L19.74 21.6L21.15 20.19L3.42 2.45001L2.01 3.87001ZM9.51 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.45 9.51 11.42 9.51 11.37ZM6.11 7.97001L7.86 9.72001C7.63 10.27 7.5 10.87 7.5 11.5C7.5 13.98 9.52 16 12 16C12.63 16 13.23 15.87 13.77 15.64L14.75 16.62C13.87 16.86 12.95 17 12 17C8.21 17 4.83 14.87 3.18 11.5C3.88 10.07 4.9 8.89001 6.11 7.97001Z"
        fill="currentColor"
      />
    </>
  ),
  visibility: (
    <>
      <path
        d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
        fill="currentColor"
      />
    </>
  ),
  sortDown: (
    <>
      <path
        opacity="0.4"
        d="M11.5019 19.7748C11.5643 19.8453 11.641 19.9017 11.7269 19.9404C11.8128 19.979 11.9059 19.999 12 19.999C12.0942 19.999 12.1873 19.979 12.2732 19.9404C12.3591 19.9017 12.4358 19.8453 12.4982 19.7748L16.3482 15.4433C16.731 15.0138 16.4256 14.3336 15.85 14.3336H12H8.15005C7.57518 14.3336 7.26974 15.0138 7.65187 15.444L11.5019 19.7748Z"
        fill="currentColor"
      />
      <path
        d="M8.15002 9.66609H15.8507C16.4256 9.66609 16.731 8.98585 16.3489 8.5557L12.4989 4.2242C12.4364 4.15372 12.3597 4.09728 12.2738 4.05863C12.188 4.01998 12.0949 4 12.0007 4C11.9065 4 11.8134 4.01998 11.7275 4.05863C11.6417 4.09728 11.565 4.15372 11.5025 4.2242L7.65118 8.5557C7.26905 8.98585 7.57449 9.66609 8.15002 9.66609Z"
        fill="currentColor"
      />
    </>
  ),
  sortUp: (
    <>
      <path
        d="M11.5019 19.7748C11.5643 19.8453 11.641 19.9017 11.7269 19.9404C11.8128 19.979 11.9059 19.999 12 19.999C12.0942 19.999 12.1873 19.979 12.2732 19.9404C12.3591 19.9017 12.4358 19.8453 12.4982 19.7748L16.3482 15.4433C16.731 15.0138 16.4256 14.3336 15.85 14.3336H12H8.15005C7.57518 14.3336 7.26974 15.0138 7.65187 15.444L11.5019 19.7748Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M8.15002 9.66609H15.8507C16.4256 9.66609 16.731 8.98585 16.3489 8.5557L12.4989 4.2242C12.4364 4.15372 12.3597 4.09728 12.2738 4.05863C12.188 4.01998 12.0949 4 12.0007 4C11.9065 4 11.8134 4.01998 11.7275 4.05863C11.6417 4.09728 11.565 4.15372 11.5025 4.2242L7.65118 8.5557C7.26905 8.98585 7.57449 9.66609 8.15002 9.66609Z"
        fill="currentColor"
      />
    </>
  ),
  sort: (
    <>
      <path
        d="M8.15002 9.66609H15.8507C16.4256 9.66609 16.731 8.98585 16.3489 8.5557L12.4989 4.2242C12.4364 4.15372 12.3597 4.09728 12.2738 4.05863C12.188 4.01998 12.0949 4 12.0007 4C11.9065 4 11.8134 4.01998 11.7275 4.05863C11.6417 4.09728 11.565 4.15372 11.5025 4.2242L7.65118 8.5557C7.26905 8.98585 7.57449 9.66609 8.15002 9.66609ZM11.5018 19.7748C11.5643 19.8453 11.641 19.9017 11.7269 19.9404C11.8127 19.979 11.9058 19.999 12 19.999C12.0942 19.999 12.1873 19.979 12.2732 19.9404C12.359 19.9017 12.4357 19.8453 12.4982 19.7748L16.3482 15.4433C16.731 15.0138 16.4256 14.3336 15.85 14.3336H8.15002C7.57516 14.3336 7.26972 15.0138 7.65185 15.444L11.5018 19.7748Z"
        fill="currentColor"
      />
    </>
  )
};

export default list;
