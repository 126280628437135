export const handleCalculateMonthlyInstallment = (
  amount: string = "",
  interest_rate: string = "",
  duration: string = ""
): string => {
  if (
    amount.trim() !== "" &&
    interest_rate.trim() !== "" &&
    duration.trim() !== ""
  ) {
    const monthly = amount
      ? (
          (Number(amount) * Number(interest_rate)) /
          100 /
          12 /
          (1 - Math.pow(1 + Number(interest_rate) / 100 / 12, -duration))
        )
          .toFixed(2)
          .toString()
      : "0";
    return monthly;
  } else {
    return "0";
  }
};
