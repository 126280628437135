import { AxiosError } from "axios";
import Button from "components/button";
import Input from "components/input";
import Loading from "components/loading";
import ResultBox from "components/result-box";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import {
  collateral,
  emirates,
  employment_levels,
  employment_spheres,
  family_statuses,
  genders,
  has_account_with_the_bank_y_n,
  locations,
  property_numbers,
  salary_transfer_y_n,
  sub_product_types,
} from "utils/constants";
import prettierNumber from "utils/prettier-number";
import { handleCalculateMonthlyInstallment } from "./handleCalculateMonthlyInstallment";
import { Info } from "./Info";
import { InfoItem } from "./InfoItem";
import styles from "./scoring.module.scss";
import { TypeOfProduct, useForm } from "./useForm";

const initialValidationState = {
  age: "",
  monthlyIncome: "",
  typeOfProduct: "",
  duration_at_disbursement_months: "",
  work_experience: "",
  salary_transfer_y_n: "",
  loan_amount: "",
};

const initialFormState = {
  location: {
    key: "",
    value: "",
  },
  emirates: {
    key: "",
    value: "",
  },
  sub_product_type: {
    key: "",
    value: "",
  },
  gender: {
    key: "",
    value: "",
  },
  family_status: {
    key: "",
    value: "",
  },
  employment_sphere: {
    key: "",
    value: "",
  },
  employment_level: {
    key: "",
    value: "",
  },
  is_customer_employed: {
    key: "",
    value: "",
  },
  salary_transfer_y_n: {
    key: "",
    value: "",
  },
  has_account_with_the_bank_y_n: {
    key: "",
    value: "",
  },
  collateral: {
    key: "",
    value: "",
  },
  duration_at_disbursement_months: "",
  loan_amount: "",
  interest_rate: "",
  prior_loans_at_cbi: "",
  age: "",
  monthly_income_aed: "",
  other_income_monthly: "",
  work_experience: "",
  auto_loan_payment: "",
  credit_card_payment: "",
  property_value: "",
  property_number: {
    key: "",
    value: "",
  },
  presence_of_car: "",
  presence_of_housing: "",
  aecb: "",
  number_of_years_in_uae: "",
  last_six_months_average_account_balance: "",
  number_of_loans_products_at_bank_with_cbi: "",
  car_price: "",
};

type TForm = typeof initialFormState;

const loanDurationInfo = (
  form: TForm,
  typeOfProduct: TypeOfProduct
): string => {
  if (Number(form.age) === 0) {
    return "To see maximum allowed loan duration fill age input";
  }

  let age = 0;

  if (typeOfProduct === "HOUSING LOAN") {
    age = form.employment_sphere.key === "Personal salary" ? 65 : 70;
  } else {
    age = 65;
  }

  const maxDuration = (age - Number(form.age)) * 12;

  if (typeOfProduct === "HOUSING LOAN") {
    if (
      form.employment_sphere.key === "Personal salary" &&
      Number(form.age) > 65
    ) {
      form.age = "65";
    }

    return `Maximum allowed loan duration is ${
      maxDuration > 300 ? 300 : maxDuration
    } months`;
  } else if (typeOfProduct === "CAR LOAN") {
    return `Maximum allowed loan duration is ${
      maxDuration > 60 ? 60 : maxDuration
    } months`;
  } else {
    return `Maximum allowed loan duration is ${
      maxDuration > 48 ? 48 : maxDuration
    } months`;
  }
};

const loanDurationMaxValue = (typeOfProduct: TypeOfProduct): number => {
  if (typeOfProduct === "HOUSING LOAN") {
    return 300;
  } else if (typeOfProduct === "CAR LOAN") {
    return 60;
  } else {
    return 48;
  }
};

const ageMaxValue = (typeOfProduct: TypeOfProduct, form: TForm): number => {
  if (
    typeOfProduct === "HOUSING LOAN" &&
    form.employment_sphere.key === "Personal salary"
  ) {
    return 65;
  } else if (
    typeOfProduct === "HOUSING LOAN" &&
    form.employment_sphere.key === "Personal others/self employed"
  ) {
    return 70;
  } else {
    return 65;
  }
};

const calculateProcessingFee = (
  typeOfProduct: TypeOfProduct,
  loan_amount: string,
  sub_product_type: string,
  constants: any
) => {
  if (
    typeOfProduct === "HOUSING LOAN" &&
    sub_product_type === "Pure Buyout Only"
  ) {
    return "0";
  } else if (typeOfProduct !== "HOUSING LOAN") {
    const processingFee =
      (Number(loan_amount) * Number(constants.processing_fee_rate)) / 100;
    const value =
      processingFee < 500
        ? 500
        : processingFee >= 500 && processingFee < 2500
          ? processingFee
          : processingFee >= 2500
            ? 2500
            : 0;
    return prettierNumber(Number(value?.toFixed(2)));
  } else {
    return prettierNumber(
      Number(
        (
          Number(loan_amount) * Number(constants?.processing_fee_rate / 100)
        ).toFixed(2)
      )
    );
  }
};

const Scoring = () => {
  const { id } = useParams();
  const application = useSelector((state) => state.application);
  const constants = useSelector((state) => state.constants);
  const dispatch = useDispatch();

  const [pending, setPending] = React.useState(false);
  const [validationError, setValidationError] = React.useState(
    initialValidationState
  );
  const [ltv, setLtv] = useState<number>();
  const [AECBLimits, setAECBLimits] = useState<
    {
      country_of_origin: typeof locations;
      aecb_value: number;
    }[]
  >([]);

  const {
    form,
    setForm,
    formDebounced,
    monthlyInstallment,
    setMonthlyInstallment,
    handleInputChange,
    handleSelectChange,
    prepareRequestData,
    isFormValid,
  } = useForm<TForm>({
    initialState: initialFormState,
    typeOfProduct: application?.type_of_product as TypeOfProduct,
  });

  const handleRunScoring = () => {
    if (!form) return;
    let timeout: NodeJS.Timeout;
    const typeOfProduct = application?.type_of_product;

    let newValidationError = { ...initialValidationState };
    const maxAge = form.employment_sphere.key === "Personal salary" ? 65 : 70;

    if (
      Number(form.monthly_income_aed) + Number(form.other_income_monthly) <
      15000
    )
      newValidationError.monthlyIncome = `Sorry, to be eligible for a loan application you must have at least AED 15 000 monthly income`;

    if (
      Number(form.duration_at_disbursement_months) >
      (maxAge - Number(form.age)) * 12
    ) {
      newValidationError.duration_at_disbursement_months = `Maximum allowed loan duration is ${
        (maxAge - Number(form.age)) * 12
      } months`;
    }

    if (
      typeOfProduct === "HOUSING LOAN" &&
      7 * 12 * Number(form.monthly_income_aed) +
        Number(form.other_income_monthly) <
        Number(application?.loan_amount)
    )
      newValidationError.monthlyIncome = `For product type "HOUSING LOAN", the loan amount shall not exceed 7 * 12 * all monthly income`;

    if (
      typeOfProduct === "PERSONAL LOAN" &&
      application?.nationality === "expat"
    ) {
      const maxLimint =
        (Number(form.monthly_income_aed.replaceAll(",", "")) +
          Number(form.other_income_monthly.replaceAll(",", ""))) *
        20;

      if (maxLimint < Number(form.loan_amount)) {
        newValidationError.loan_amount = `The loan amount for a personal loan should not be more than ${maxLimint} (total income x 20), in case of Expats`;
      } else if (Number(form.loan_amount) > 1000000) {
        newValidationError.loan_amount = `The loan amount for a personal loan should not be more than 1,000,000 in case of Expat`;
      }
    }

    if (
      typeOfProduct === "PERSONAL LOAN" &&
      application?.nationality === "uae_national"
    ) {
      const maxLimint =
        (Number(form.monthly_income_aed.replaceAll(",", "")) +
          Number(form.other_income_monthly.replaceAll(",", ""))) *
        20;

      if (maxLimint < Number(form.loan_amount)) {
        newValidationError.loan_amount = `The loan amount for a personal loan should not be more than ${maxLimint} (total income x 20), in case of UAE national`;
      } else if (Number(form.loan_amount) > 2000000) {
        newValidationError.loan_amount = `The loan amount for a personal loan should not be more than 2,000,000 in case of UAE national`;
      }
    }

    if (typeOfProduct === "HOUSING LOAN" && Number(form.loan_amount) > 25000000)
      newValidationError.loan_amount = `The maximum value for the "HOUSING LOAN"  is 25,000,000`;

    if (
      typeOfProduct === "PERSONAL LOAN" &&
      Number(form.duration_at_disbursement_months) > 48
    )
      newValidationError.duration_at_disbursement_months = `The maximum value for the "PERSONAL LOAN" product type is 48 months`;

    if (
      typeOfProduct === "CAR LOAN" &&
      Number(form.duration_at_disbursement_months) > 60
    )
      newValidationError.duration_at_disbursement_months = `The maximum value for the "CAR LOAN" product type is 60 months`;
    if (
      typeOfProduct === "HOUSING LOAN" &&
      Number(form.duration_at_disbursement_months) > 300
    )
      newValidationError.duration_at_disbursement_months = `The maximum value for the "HOUSING LOAN" product type is 300 months`;
    if (
      typeOfProduct === "CAR LOAN" &&
      form.salary_transfer_y_n.key === "Y" &&
      Number(form?.loan_amount) > 1000000
    )
      newValidationError.loan_amount = `Loan amount shall not exceed 1M AED if it is a Salary transfer`;
    if (
      typeOfProduct === "CAR LOAN" &&
      form.salary_transfer_y_n.key === "N" &&
      Number(form?.loan_amount) > 750000
    )
      newValidationError.loan_amount = `Loan amount shall not exceed 750k AED if it is not a Salary transfer `;
    if (Number(form.work_experience) > Number(form.number_of_years_in_uae))
      newValidationError.work_experience =
        "Length of service should be less than or equal to number of months in UAE";

    if (Number(form.monthly_income_aed) > 100000000) {
      newValidationError.monthlyIncome = `Provided value is greater than limit (limit: ${prettierNumber(
        100000000
      )})`;
    }

    if (
      typeOfProduct === "HOUSING LOAN" &&
      form.employment_sphere.key === "Personal salary" &&
      Number(form.age) > 65
    ) {
      newValidationError.age =
        "Age cannot be greater than 65 in case of Employment type 'Personal salary'";
    } else if (
      typeOfProduct === "HOUSING LOAN" &&
      form.employment_sphere.key === "Personal others/self employed" &&
      Number(form.age) > 70
    ) {
      newValidationError.age =
        "Age cannot be greater than 70 in case of Employment type 'Personal others/self employed'";
    } else if (typeOfProduct !== "HOUSING LOAN" && Number(form.age) > 65) {
      newValidationError.age = "Age cannot be greater than 65";
    }

    setValidationError(newValidationError);

    if (
      JSON.stringify(newValidationError) !==
      JSON.stringify(initialValidationState)
    ) {
      toast.error("Please correct all errors before submitting.");
      return;
    }

    setPending(true);

    const object = prepareRequestData(
      form,
      application?.type_of_product as TypeOfProduct
    );
    request
      .post(`/applications/${id}/scoring`, object)
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(() => {
          if (id) dispatch.application.FETCH_APPLICATION_BY_ID({ id });
        }, 1000);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          if (e.response?.status === 409) {
            toast.error(e?.response?.data?.detail ?? "Error");
          } else if (e.response?.status === 422) {
            toast.error(e?.response?.data?.detail[0].msg ?? "Error");
          }
        }
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  React.useEffect(() => {
    request
      .get("/admin/countries_aecb")
      .then((res) => res.data)
      .then((data) => {
        setAECBLimits(
          data.map((c: any) => ({
            country_of_origin: c.country_of_origin.toUpperCase(),
            aecb_value: c.aecb_value,
          }))
        );
      });
  }, []);

  React.useEffect(() => {
    if (!constants) return;

    const typeOfProduct = application?.type_of_product;
    if (typeOfProduct === "PERSONAL LOAN") {
      setLtv(undefined);
    } else if (application?.ltv_log) {
      setLtv(application.ltv_log?.ltv_threshold);
    } else if (typeOfProduct === "CAR LOAN") {
      if (form?.sub_product_type.key === "") {
        setLtv(undefined);
      } else {
        if (form?.sub_product_type.key === "New Car") {
          setLtv(constants.ltv_new_car_threshold);
        } else {
          setLtv(constants.ltv_used_car_threshold);
        }
      }
    } else {
      if (application?.nationality === "uae_national") {
        setLtv(constants?.ltv_national_housing_threshold);
      } else {
        setLtv(constants.ltv_expat_housing_threshold);
      }
    }
    // eslint-disable-next-line
  }, [constants, application?.scoring_log, form?.sub_product_type.key]);

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [draftData, setDraftData] = useState(null);
  const [savePending, setSavePending] = useState(false);

  React.useEffect(() => {
    request
      .get("applications/draft/" + id)
      .then((res) => res.data)
      .then((res) => {
        setForm({
          location: {
            key: res.location ? String(res.location) : "",
            value: res.location ? String(res.location) : "",
          },
          emirates: {
            key: res.emirates ? String(res.emirates) : "",
            value: res.emirates ? String(res.emirates) : "",
          },
          sub_product_type: {
            key: res.sub_product_type ? String(res.sub_product_type) : "",
            value: res.sub_product_type ? String(res.sub_product_type) : "",
          },
          gender: {
            key: res.gender ? String(res.gender) : "",
            value: res.gender ? String(res.gender) : "",
          },
          family_status: {
            key: res.family_status ? String(res.family_status) : "",
            value: res.family_status ? String(res.family_status) : "",
          },
          employment_sphere: {
            key: res.employment_sphere ? String(res.employment_sphere) : "",
            value: res.employment_sphere ? String(res.employment_sphere) : "",
          },
          employment_level: {
            key: res.employment_level ? String(res.employment_level) : "",
            value: res.employment_level ? String(res.employment_level) : "",
          },
          is_customer_employed: {
            key:
              res.is_customer_employed === true
                ? "1"
                : res.is_customer_employed === false
                  ? "0"
                  : "",
            value:
              res.is_customer_employed === true
                ? "Yes"
                : res.is_customer_employed === false
                  ? "No"
                  : "",
          },
          salary_transfer_y_n: {
            key:
              res.salary_transfer_y_n === "Y"
                ? "Y"
                : res.salary_transfer_y_n === "N"
                  ? "N"
                  : "",
            value:
              res.salary_transfer_y_n === "Y"
                ? "Yes"
                : res.salary_transfer_y_n === "N"
                  ? "No"
                  : "",
          },
          has_account_with_the_bank_y_n: {
            key:
              res.has_account_with_the_bank_y_n === 1
                ? "1"
                : res.has_account_with_the_bank_y_n === 0
                  ? "0"
                  : "",
            value:
              res.has_account_with_the_bank_y_n === 1
                ? "Yes"
                : res.has_account_with_the_bank_y_n === 0
                  ? "No"
                  : "",
          },
          collateral: {
            key:
              res.collateral === "Yes"
                ? "Yes"
                : res.collateral === "No"
                  ? "No"
                  : "",
            value:
              res.collateral === "Yes"
                ? "Yes"
                : res.collateral === "No"
                  ? "No"
                  : "",
          },
          duration_at_disbursement_months: res.duration_at_disbursement_months
            ? String(res.duration_at_disbursement_months)
            : "",
          prior_loans_at_cbi:
            res.prior_loans_at_cbi || res.prior_loans_at_cbi === 0
              ? String(res.prior_loans_at_cbi)
              : "",
          age: res.age ? String(res.age) : "",
          loan_amount: res.loan_amount ? String(res.loan_amount) : "",
          interest_rate: res.interest_rate ? String(res.interest_rate) : "",
          monthly_income_aed: res.monthly_income_aed
            ? String(res.monthly_income_aed)
            : "",
          other_income_monthly:
            res.other_income_monthly || res.other_income_monthly === 0
              ? String(res.other_income_monthly)
              : "",
          work_experience: res.work_experience
            ? String(res.work_experience)
            : "",
          auto_loan_payment:
            res.auto_loan_payment || res.auto_loan_payment === 0
              ? String(res.auto_loan_payment)
              : "",
          credit_card_payment:
            res.credit_card_payment || res.credit_card_payment === 0
              ? String(res.credit_card_payment)
              : "",
          property_value:
            res.property_value || res.property_value === 0
              ? String(res.property_value)
              : "",
          property_number: {
            key: res.property_number ? String(res.property_number) : "",
            value: res.property_number ? String(res.property_number) : "",
          },
          presence_of_car:
            res.presence_of_car || res.presence_of_car === 0
              ? String(res.presence_of_car)
              : "",
          presence_of_housing:
            res.presence_of_housing || res.presence_of_housing === 0
              ? String(res.presence_of_housing)
              : "",
          aecb: res.aecb ? String(res.aecb) : "",
          number_of_years_in_uae: res.number_of_years_in_uae
            ? String(res.number_of_years_in_uae)
            : "",
          last_six_months_average_account_balance:
            res.last_six_months_average_account_balance ||
            res.last_six_months_average_account_balance === 0
              ? String(res.last_six_months_average_account_balance)
              : "",
          number_of_loans_products_at_bank_with_cbi:
            res.number_of_loans_products_at_bank_with_cbi ||
            res.number_of_loans_products_at_bank_with_cbi === 0
              ? String(res.number_of_loans_products_at_bank_with_cbi)
              : "",
          car_price: res.car_price ? String(res.car_price) : "",
        });
        if (res.duration_at_disbursement_months !== null) {
          const m = handleCalculateMonthlyInstallment(
            res.loan_amount ? String(res.loan_amount) : "",
            res.interest_rate ? res.interest_rate.toFixed(1) : "",
            res.duration_at_disbursement_months
              ? String(res.duration_at_disbursement_months)
              : ""
          );
          setMonthlyInstallment(m);
        }
      })
      .catch(() => {
        setForm(initialFormState);
      })
      .finally(() => {
        setInitialDataLoaded(true);
      });
  }, [id, setMonthlyInstallment, setForm]);

  const debouncedSaveDraft = useMemo(
    () =>
      debounce((data: any) => {
        setSavePending(true);
        request
          .post("/applications/draft/" + id, data)
          .catch((err: unknown) => console.error(err))
          .finally(() => setSavePending(false));
      }, 500),
    [id]
  );

  const [formValid, setFormValid] = useState(false);

  React.useEffect(() => {
    setFormValid(
      form?.loan_amount !== "" &&
        form?.location.key !== "" &&
        form?.emirates.key !== "" &&
        form?.gender.key !== "" &&
        form?.interest_rate.trim() !== "" &&
        form?.family_status.key !== "" &&
        form?.employment_sphere.key !== "" &&
        form?.employment_level.key !== "" &&
        form?.duration_at_disbursement_months !== "" &&
        form?.prior_loans_at_cbi !== "" &&
        monthlyInstallment !== "" &&
        form?.other_income_monthly !== "" &&
        form?.age !== "" &&
        form?.monthly_income_aed !== "" &&
        form?.work_experience !== "" &&
        form?.auto_loan_payment !== "" &&
        form?.credit_card_payment !== "" &&
        form?.presence_of_car !== "" &&
        form?.is_customer_employed.key !== "" &&
        form?.sub_product_type.key !== "" &&
        form?.presence_of_housing !== "" &&
        (application?.type_of_product !== "HOUSING LOAN" ||
          (form?.property_number.key !== "" && form?.property_value !== "")) &&
        form?.aecb !== "" &&
        form?.salary_transfer_y_n.key !== "" &&
        form?.number_of_years_in_uae !== "" &&
        form?.has_account_with_the_bank_y_n.key !== "" &&
        form?.last_six_months_average_account_balance !== "" &&
        form?.collateral.key !== "" &&
        form?.number_of_loans_products_at_bank_with_cbi !== ""
    );
  }, [
    application?.loan_amount,
    application?.nationality,
    application?.type_of_product,
    form,
    monthlyInstallment,
  ]);

  useEffect(() => {
    if (!initialDataLoaded || !formDebounced) return;
    const newDraftData: any = {
      loan_amount:
        formDebounced.loan_amount === ""
          ? 0
          : Number(formDebounced.loan_amount?.replaceAll(",", "")),
      interest_rate:
        formDebounced.interest_rate === ""
          ? 0
          : Number(formDebounced.interest_rate.replaceAll(" ", "")),
      duration_at_disbursement_months:
        formDebounced.duration_at_disbursement_months === ""
          ? 0
          : Number(
              formDebounced.duration_at_disbursement_months?.replaceAll(" ", "")
            ),
      age: formDebounced.age === "" ? 0 : Number(formDebounced.age),
      monthly_income_aed:
        formDebounced.monthly_income_aed === ""
          ? 0
          : Number(formDebounced.monthly_income_aed?.replaceAll(" ", "")),
      other_income_monthly:
        formDebounced.other_income_monthly === ""
          ? null
          : Number(formDebounced.other_income_monthly?.replaceAll(" ", "")),
      work_experience:
        formDebounced.work_experience === ""
          ? 0
          : Number(formDebounced.work_experience?.replaceAll(" ", "")),
      presence_of_housing:
        formDebounced.presence_of_housing === ""
          ? null
          : Number(formDebounced.presence_of_housing?.replaceAll(" ", "")),
      presence_of_car:
        formDebounced.presence_of_car === ""
          ? null
          : Number(formDebounced.presence_of_car?.replaceAll(" ", "")),
      prior_loans_at_cbi:
        formDebounced.prior_loans_at_cbi === ""
          ? null
          : Number(formDebounced.prior_loans_at_cbi?.replaceAll(" ", "")),
      aecb:
        formDebounced.aecb === ""
          ? 0
          : Number(formDebounced.aecb?.replaceAll(" ", "")),
      number_of_years_in_uae:
        formDebounced.number_of_years_in_uae === ""
          ? 0
          : Number(formDebounced.number_of_years_in_uae?.replaceAll(" ", "")),
      has_account_with_the_bank_y_n:
        formDebounced.has_account_with_the_bank_y_n.key === ""
          ? 0
          : Number(formDebounced.has_account_with_the_bank_y_n.key),
      credit_card_payment:
        formDebounced.credit_card_payment === ""
          ? null
          : Number(formDebounced.credit_card_payment?.replaceAll(" ", "")),
      auto_loan_payment:
        formDebounced.auto_loan_payment === ""
          ? null
          : Number(formDebounced.auto_loan_payment),
      last_six_months_average_account_balance:
        form.last_six_months_average_account_balance === ""
          ? null
          : Number(
              formDebounced.last_six_months_average_account_balance?.replaceAll(
                " ",
                ""
              )
            ),
      number_of_loans_products_at_bank_with_cbi:
        form.number_of_loans_products_at_bank_with_cbi === ""
          ? null
          : Number(
              formDebounced.number_of_loans_products_at_bank_with_cbi?.replaceAll(
                " ",
                ""
              )
            ),
      car_price:
        formDebounced.car_price === ""
          ? 0
          : formDebounced.car_price?.replaceAll(" ", ""),
      country_of_origin:
        formDebounced.location.key === "" ? "" : formDebounced.location.key,
      employment_sphere:
        formDebounced.employment_sphere.key === ""
          ? undefined
          : formDebounced.employment_sphere.key,
      gender:
        formDebounced.gender.key === "" ? undefined : formDebounced.gender.key,
      family_status:
        formDebounced.family_status.key === ""
          ? undefined
          : formDebounced.family_status.key,
      is_customer_employed:
        formDebounced.is_customer_employed.key === ""
          ? 0
          : Number(formDebounced.is_customer_employed.key),
      sub_product_type:
        formDebounced.sub_product_type.key === ""
          ? undefined
          : formDebounced.sub_product_type.key,
      emirates:
        formDebounced.emirates.key === ""
          ? undefined
          : formDebounced.emirates.key,
      employment_level:
        formDebounced.employment_level.key === ""
          ? undefined
          : formDebounced.employment_level.key,
      location:
        formDebounced.location.key === ""
          ? undefined
          : formDebounced.location.key,
      collateral:
        formDebounced.collateral.key === ""
          ? undefined
          : formDebounced.collateral.key,
      salary_transfer_y_n:
        formDebounced.salary_transfer_y_n.key === ""
          ? undefined
          : formDebounced.salary_transfer_y_n.key,
      property_value:
        formDebounced.property_value === ""
          ? null
          : Number(formDebounced.property_value?.replaceAll(" ", "")),
      property_number:
        formDebounced.property_number.key === ""
          ? undefined
          : formDebounced.property_number.key,
    };

    if (JSON.stringify(newDraftData) !== JSON.stringify(draftData)) {
      debouncedSaveDraft(newDraftData);
      setDraftData(newDraftData);
    }
  }, [formDebounced, initialDataLoaded, debouncedSaveDraft, draftData]);

  if (!form) return <></>;
  return (
    <div className={styles.home}>
      <div className={styles.fields}>
        <Info title="Application data">
          <>
            <InfoItem title="Application ID" value={application?.id ?? 0} />
            <InfoItem
              title="Type of product"
              value={application?.type_of_product ?? ""}
            />
            <InfoItem
              title="Customer name"
              value={application?.customer_name ?? ""}
            />
            <InfoItem
              title="Customer type"
              value={
                application?.nationality === "uae_national"
                  ? "UAE national"
                  : "Expat"
              }
            />
          </>
        </Info>
        <Info title="Loan limits">
          <>
            <InfoItem
              title="Scoring threshold"
              value={`${
                application?.scoring_log?.threshold
                  ? application?.scoring_log?.threshold
                  : Math.round(constants?.threshold ?? 0)
              } %`}
            />
            {/* {application?.type_of_product !== "HOUSING LOAN" && (
            )} */}
            <InfoItem
              title="DSR threshold"
              value={`${Number(
                application?.dsr_log
                  ? application?.dsr_log?.dsr_threshold
                  : constants?.dsr_threshold
              )} %`}
            />
            <InfoItem
              title="Agency Score limit"
              value={
                application?.aecb_score_log?.aecb_score_limit ??
                AECBLimits.find(
                  (a) => String(a.country_of_origin) === form?.location.key
                )?.aecb_value ??
                AECBLimits.find(
                  (a) => String(a.country_of_origin) === "DEFAULT"
                )?.aecb_value ??
                ""
              }
            />
            {/* {application?.type_of_product === "HOUSING LOAN" && (
              <InfoItem
                title="LTV limit"
                value={`${
                  application?.nationality.toLowerCase() === "expat"
                    ? String(constants?.ltv_expat_housing_threshold)
                    : String(constants?.ltv_national_housing_threshold)
                } %`}
              />
            )} */}
          </>
        </Info>
        {/* Macro variables */}
        <Info title="Macro variables">
          <InfoItem
            title="Inflation rate"
            value={
              application?.scoring_log?.inflation_rate ??
              constants?.inflation_rate ??
              ""
            }
          />
          <InfoItem
            title="Manufacturing PMI"
            value={
              application?.scoring_log?.manufacturing_PMI ??
              constants?.manufacturing_PMI ??
              ""
            }
          />
          <InfoItem
            title="CPI"
            value={application?.scoring_log?.CPI ?? constants?.CPI ?? ""}
          />
        </Info>
        {!!application?.scoring_log && (
          <>
            {/* Loan conditions */}
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Loan conditions</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>Loan amount</div>
                  <div className={styles.value}>
                    {prettierNumber(application?.loan_amount ?? 0)} AED
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Interest rate</div>
                  <div className={styles.value}>
                    {`${Number(application.interest_rate).toFixed(2)}%`}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Loan duration (months)</div>
                  <div className={styles.value}>
                    {application.scoring_log.duration_at_disbursement_months}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Collateral</div>
                  <div className={styles.value}>
                    {application.scoring_log.collateral}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Sub-product type</div>
                  <div className={styles.value}>
                    {application.scoring_log.sub_product_type}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Monthly installment</div>
                  <div className={styles.value}>
                    {Number(
                      application.scoring_log.monthly_installment
                    ).toFixed(2)}
                  </div>
                </div>
                {application.aecb_score_log && (
                  <div className={styles.item}>
                    <div className={styles.key}>Agency Score</div>
                    <div className={styles.value}>
                      {application.aecb_score_log?.value}
                    </div>
                  </div>
                )}
                {application?.type_of_product !== "PERSONAL LOAN" &&
                  application?.type_of_product !== "CAR LOAN" &&
                  application.ltv_log && (
                    <div className={styles.item}>
                      <div className={styles.key}>LTV</div>
                      <div className={styles.value}>
                        {(application.ltv_log?.value).toFixed(2)} %
                      </div>
                    </div>
                  )}
                <div className={styles.item}>
                  <div className={styles.key}>DSR</div>
                  <div className={styles.value}>
                    {Number(application.dsr_log?.value).toFixed(2)} %
                  </div>
                </div>

                {application?.scoring_log?.is_approved !== null && (
                  <div className={styles.item}>
                    <div className={styles.key}>Scoring prediction</div>
                    <div className={styles.value}>
                      {application?.scoring_log?.prediction} %
                    </div>
                  </div>
                )}
                <div className={styles.item}>
                  <div className={styles.key}>
                    Other loan monthly installment
                  </div>
                  <div className={styles.value}>
                    {prettierNumber(
                      Number(application?.scoring_log?.auto_loan_payment)
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Credit card payment</div>
                  <div className={styles.value}>
                    {prettierNumber(
                      Number(application?.scoring_log?.credit_card_payment)
                    )}
                  </div>
                </div>
                {/* {application?.type_of_product !== "HOUSING LOAN" && (
                  <div className={styles.item}>
                    <div className={styles.key}>Processing fee</div>
                    <div className={styles.value}>
                      {calculateProcessingFee(
                        application.type_of_product as TypeOfProduct,
                        String(application.loan_amount),
                        application.draft.sub_product_type,
                        constants
                      )}
                    </div>
                  </div>
                )} */}
                {/* {(application?.type_of_product === "CAR LOAN" ||
                  application?.type_of_product === "PERSONAL LOAN") &&
                  (Number(application?.loan_amount) *
                    Number(constants?.processing_fee_rate) <=
                  2500 ? (
                    <div className={styles.item}>
                      <div className={styles.key}>
                        Processing fee ({Number(constants?.processing_fee_rate)}
                        %)
                      </div>
                      <div className={styles.value}>
                        {prettierNumber(
                          Number(
                            application?.scoring_log
                              ?.processing_fee_x_from_the_loan
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.item}>
                      <div className={styles.key}>Processing fee</div>
                      <div className={styles.value}>2,500</div>
                    </div>
                  ))} */}
                {/* {application?.type_of_product === "HOUSING LOAN" && (
                    )} */}
                <div className={styles.item}>
                  <div className={styles.key}>Processing fee</div>
                  <div className={styles.value}>
                    {calculateProcessingFee(
                      // @ts-ignore
                      application.type_of_product,
                      String(application.loan_amount),
                      application.draft?.sub_product_type,
                      constants
                    )}
                  </div>
                </div>
                {application.type_of_product === "HOUSING LOAN" && (
                  <>
                    <div className={styles.item}>
                      <div className={styles.key}>Property value</div>
                      <div className={styles.value}>
                        {prettierNumber(
                          application?.scoring_log?.property_value
                        )}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Property number</div>
                      <div className={styles.value}>
                        {application.scoring_log?.property_number}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Customer data */}
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Customer data</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>Nationality</div>
                  <div className={styles.value}>
                    {application.scoring_log.location}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Emirates</div>
                  <div className={styles.value}>
                    {application.scoring_log.emirates}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Gender</div>
                  <div className={styles.value}>
                    {application.scoring_log.gender}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Family status</div>
                  <div className={styles.value}>
                    {application.scoring_log.family_status}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Employment type</div>
                  <div className={styles.value}>
                    {application.scoring_log.employment_sphere}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Designation</div>
                  <div className={styles.value}>
                    {application.scoring_log.employment_level}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Prior loans at CBI</div>
                  <div className={styles.value}>
                    {application.scoring_log.prior_loans_at_cbi}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Age</div>
                  <div className={styles.value}>
                    {application.scoring_log.age}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Monthly income</div>
                  <div className={styles.value}>
                    {prettierNumber(application.scoring_log.monthly_income_aed)}{" "}
                    AED
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Other monthly income</div>
                  <div className={styles.value}>
                    {prettierNumber(
                      application.scoring_log.other_income_monthly
                    )}{" "}
                    AED
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    Length of service in current company (in months)
                  </div>
                  <div className={styles.value}>
                    {application.scoring_log.work_experience}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Salary transfer</div>
                  <div className={styles.value}>
                    {application.scoring_log.salary_transfer_y_n === "Y"
                      ? "Yes"
                      : "NO"}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    Number of loan products at CBI
                  </div>
                  <div className={styles.value}>
                    {
                      application.scoring_log
                        .number_of_loans_products_at_bank_with_cbi
                    }
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    Last six months average account balance
                  </div>
                  <div className={styles.value}>
                    {prettierNumber(
                      application.scoring_log
                        .last_six_months_average_account_balance
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Has account with the bank</div>
                  <div className={styles.value}>
                    {application.scoring_log.has_account_with_the_bank_y_n === 1
                      ? "YES"
                      : "NO"}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Number of months in UAE</div>
                  <div className={styles.value}>
                    {application.scoring_log.number_of_years_in_uae}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Number of cars owned</div>
                  <div className={styles.value}>
                    {application.scoring_log.presence_of_car}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Number of property owned</div>
                  <div className={styles.value}>
                    {application.scoring_log.presence_of_housing}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Is customer employed?</div>
                  <div className={styles.value}>
                    {application.scoring_log.is_customer_employed
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* // ! INPUTS HERE */}
        {!application?.scoring_log && (
          <>
            {/* Loan conditions */}
            <div className={styles.fieldGroup}>
              <div className={styles.fieldTitle}>Loan conditions</div>
              <div className={styles.fieldList}>
                <Input
                  title="Loan amount"
                  type="integer"
                  value={
                    form.loan_amount !== ""
                      ? prettierNumber(Number(form.loan_amount))
                      : ""
                  }
                  onInput={(value) => {
                    const m = handleCalculateMonthlyInstallment(
                      value,
                      form.interest_rate,
                      form.duration_at_disbursement_months
                    );
                    setMonthlyInstallment(m);
                    handleInputChange("loan_amount", value);
                  }}
                  step={1000}
                  min={1}
                  unit="AED"
                  error={validationError.loan_amount}
                />
                <Input
                  title="Interest rate"
                  type="float"
                  value={form.interest_rate}
                  onInput={(value) => {
                    const m = handleCalculateMonthlyInstallment(
                      form.loan_amount,
                      value,
                      form.duration_at_disbursement_months
                    );
                    setMonthlyInstallment(m);
                    handleInputChange("interest_rate", value);
                  }}
                  step={0.5}
                  min={0.5}
                  max={14.5}
                  unit="%"
                  placeholder="min: 0.5  max: 14.5"
                />
                <Input
                  title="Loan duration (months)"
                  value={form.duration_at_disbursement_months}
                  type="integer"
                  onInput={(value) => {
                    const m = handleCalculateMonthlyInstallment(
                      form.loan_amount,
                      form.interest_rate,
                      value
                    );
                    setMonthlyInstallment(m);
                    handleInputChange("duration_at_disbursement_months", value);
                  }}
                  step={1}
                  min={2}
                  max={loanDurationMaxValue(
                    application?.type_of_product as TypeOfProduct
                  )}
                  error={validationError.duration_at_disbursement_months}
                  info={
                    !validationError.duration_at_disbursement_months
                      ? loanDurationInfo(
                          form,
                          application?.type_of_product as TypeOfProduct
                        )
                      : ""
                  }
                />
                <Input
                  title="Collateral"
                  id="collateral"
                  value={form.collateral.key}
                  type="select"
                  options={collateral.map((collateral) => ({
                    key: collateral,
                    value: collateral,
                  }))}
                  onSelect={(option) =>
                    handleSelectChange("collateral", option)
                  }
                />
                <Input
                  title="Agency Score"
                  value={form.aecb}
                  type="float"
                  onInput={(value) => handleInputChange("aecb", value)}
                  step={1}
                  min={300}
                  max={900}
                />
                <Input
                  title="Sub-product type"
                  id="sub_product_type"
                  value={form.sub_product_type.key}
                  type="select"
                  options={sub_product_types(
                    application?.type_of_product ?? ""
                  ).map((sub_product_type) => ({
                    key: sub_product_type,
                    value: sub_product_type,
                  }))}
                  onSelect={(option) =>
                    handleSelectChange("sub_product_type", option)
                  }
                />
                <Input
                  title="Monthly installment"
                  disabled
                  value={
                    monthlyInstallment !== ""
                      ? prettierNumber(Number(monthlyInstallment))
                      : ""
                  }
                  type="integer"
                />
                <Input
                  title="Other loan monthly installment"
                  value={
                    form.auto_loan_payment !== ""
                      ? prettierNumber(Number(form.auto_loan_payment))
                      : ""
                  }
                  type="integer"
                  onInput={(value) =>
                    handleInputChange("auto_loan_payment", value)
                  }
                  step={1}
                />
                <Input
                  title="Credit card payment"
                  value={
                    form.credit_card_payment !== ""
                      ? prettierNumber(Number(form.credit_card_payment))
                      : ""
                  }
                  type="integer"
                  onInput={(value) =>
                    handleInputChange("credit_card_payment", value)
                  }
                  step={1}
                />

                {/* {application?.type_of_product === "HOUSING LOAN" && (
)} */}
                <Input
                  title="Processing fee"
                  disabled
                  value={calculateProcessingFee(
                    // @ts-ignore
                    application.type_of_product as TypeOfProduct,
                    form.loan_amount,
                    form.sub_product_type.key,
                    constants
                  )}
                  type="number"
                />
                {/* {application?.type_of_product !== "HOUSING LOAN" && (
                  <Input
                    title="Processing fee"
                    disabled
                    value={calculateProcessingFee(
                      // @ts-ignore
                      application.type_of_product as TypeOfProduct,
                      form.loan_amount,
                      form.sub_product_type.key,
                      constants
                    )}
                    type="number"
                  />
                )} */}

                {application?.type_of_product === "CAR LOAN" && (
                  <Input
                    title="Car price"
                    value={
                      // @ts-ignore
                      form.car_price
                        ? prettierNumber(Number(form.car_price))
                        : ""
                    }
                    onInput={(value) => {
                      handleInputChange("car_price", value);
                    }}
                    type="integer"
                    step={1000}
                    min={1000}
                    unit="AED"
                  />
                )}

                {application?.type_of_product === "HOUSING LOAN" && (
                  <>
                    <Input
                      title="Property value"
                      value={
                        form.property_value !== ""
                          ? prettierNumber(Number(form.property_value))
                          : ""
                      }
                      onInput={(value) =>
                        handleInputChange("property_value", value)
                      }
                      type="integer"
                      min={1}
                      max={1000000000}
                    />
                    <Input
                      title="Property number"
                      id="property_number"
                      value={form.property_number.key}
                      type="select"
                      options={property_numbers.map((property_number) => ({
                        key: property_number,
                        value: property_number,
                      }))}
                      onSelect={(option) =>
                        handleSelectChange("property_number", option)
                      }
                    />
                  </>
                )}
              </div>
            </div>
            {/* Customer data */}
            <div className={styles.fieldGroup}>
              <div className={styles.fieldTitle}>Customer data</div>
              <div className={styles.fieldList}>
                <Input
                  hasFilterText
                  title="Nationality"
                  id="location"
                  value={form.location.key}
                  type="select"
                  options={locations.map((location) => ({
                    key: location,
                    value: location,
                  }))}
                  onSelect={(option) => handleSelectChange("location", option)}
                />
                <Input
                  title="Emirates"
                  id="emirates"
                  value={form.emirates.key}
                  type="select"
                  options={emirates.map((emirate) => ({
                    key: emirate,
                    value: emirate,
                  }))}
                  onSelect={(option) => handleSelectChange("emirates", option)}
                />
                <Input
                  title="Gender"
                  id="gender"
                  value={form.gender.key}
                  type="select"
                  options={genders.map((gender) => ({
                    key: gender,
                    value: gender,
                  }))}
                  onSelect={(option) => handleSelectChange("gender", option)}
                />
                <Input
                  title="Family status"
                  id="family_status"
                  value={form.family_status.key}
                  type="select"
                  options={family_statuses.map((family_status) => ({
                    key: family_status,
                    value: family_status,
                  }))}
                  onSelect={(option) =>
                    handleSelectChange("family_status", option)
                  }
                />
                <Input
                  title="Employment type"
                  id="employment_sphere"
                  value={form.employment_sphere.key}
                  type="select"
                  options={employment_spheres.map((employment_sphere) => ({
                    key: employment_sphere,
                    value: employment_sphere,
                  }))}
                  onSelect={(option) =>
                    handleSelectChange("employment_sphere", option)
                  }
                />
                <Input
                  title="Designation"
                  hasFilterText
                  id="employment_level"
                  value={form.employment_level.key}
                  type="select"
                  options={employment_levels.map((employment_level) => ({
                    key: employment_level,
                    value: employment_level,
                  }))}
                  onSelect={(option) =>
                    handleSelectChange("employment_level", option)
                  }
                />
                <Input
                  title="Prior loans at CBI"
                  value={form.prior_loans_at_cbi}
                  type="integer"
                  onInput={(value) =>
                    handleInputChange("prior_loans_at_cbi", value)
                  }
                  step={1}
                  min={0}
                  max={32}
                />
                <Input
                  title="Age"
                  value={form.age}
                  type="integer"
                  onInput={(value) => handleInputChange("age", value)}
                  step={1}
                  min={21}
                  max={ageMaxValue(
                    application?.type_of_product as TypeOfProduct,
                    form
                  )}
                  error={validationError.age}
                />
                <Input
                  title="Monthly income (AED)"
                  value={
                    form.monthly_income_aed !== ""
                      ? prettierNumber(Number(form.monthly_income_aed))
                      : ""
                  }
                  type="integer"
                  onInput={(value) =>
                    handleInputChange("monthly_income_aed", value)
                  }
                  step={1}
                  max={100000000}
                  min={15000}
                  placeholder="min: 15000 max: 100000000"
                  error={validationError.monthlyIncome}
                />
                <Input
                  title="Other monthly income (AED)"
                  value={
                    form.other_income_monthly !== ""
                      ? prettierNumber(Number(form.other_income_monthly))
                      : ""
                  }
                  type="integer"
                  onInput={(value) =>
                    handleInputChange("other_income_monthly", value)
                  }
                  step={1}
                  max={200000000}
                />
                <Input
                  title="Length of service in current company (in months)"
                  value={form.work_experience}
                  type="float"
                  onInput={(value) =>
                    handleInputChange("work_experience", value)
                  }
                  error={validationError.work_experience}
                  step={1}
                  min={1.5}
                  max={1200}
                />
                <Input
                  title="Salary transfer"
                  id="salary_transfer_y_n"
                  value={form.salary_transfer_y_n.key}
                  type="select"
                  options={salary_transfer_y_n.map((salary_transfer_y_n) => ({
                    key: salary_transfer_y_n,
                    value: salary_transfer_y_n === "Y" ? "Yes" : "No",
                  }))}
                  onSelect={(option) =>
                    handleSelectChange("salary_transfer_y_n", option)
                  }
                  error={validationError.salary_transfer_y_n}
                />
                <Input
                  title="Number of loan products at CBI"
                  value={form.number_of_loans_products_at_bank_with_cbi}
                  type="float"
                  onInput={(value) =>
                    handleInputChange(
                      "number_of_loans_products_at_bank_with_cbi",
                      value
                    )
                  }
                  step={1}
                  min={0}
                  max={7}
                />
                <Input
                  title="Last six months average account balance"
                  value={
                    form.last_six_months_average_account_balance !== ""
                      ? prettierNumber(
                          Number(form.last_six_months_average_account_balance)
                        )
                      : ""
                  }
                  type="integer"
                  onInput={(value) =>
                    handleInputChange(
                      "last_six_months_average_account_balance",
                      value
                    )
                  }
                  step={1}
                  min={0}
                  max={800000}
                />
                <Input
                  title="Has account with the bank"
                  id={"has_account_with_the_bank_y_n"}
                  value={form.has_account_with_the_bank_y_n.key}
                  type="select"
                  options={has_account_with_the_bank_y_n.map(
                    (salary_transfer_y_n) => ({
                      key: salary_transfer_y_n,
                      value: salary_transfer_y_n === "1" ? "Yes" : "No",
                    })
                  )}
                  onSelect={(option) =>
                    handleSelectChange("has_account_with_the_bank_y_n", option)
                  }
                />
                <Input
                  title="Number of months in UAE"
                  value={form.number_of_years_in_uae}
                  type="float"
                  onInput={(value) =>
                    handleInputChange("number_of_years_in_uae", value)
                  }
                  step={1}
                  min={1}
                  max={1000}
                />
                <Input
                  title="Number of cars owned"
                  value={form.presence_of_car}
                  type="integer"
                  onInput={(value) =>
                    handleInputChange("presence_of_car", value)
                  }
                  step={1}
                  min={0}
                  max={12}
                />
                <Input
                  title="Number of property owned"
                  value={form.presence_of_housing}
                  type="integer"
                  onInput={(value) =>
                    handleInputChange("presence_of_housing", value)
                  }
                  step={1}
                  min={0}
                  max={14}
                />
                <Input
                  title="Is customer employed?"
                  id="is_customer_employed"
                  value={form.is_customer_employed.key}
                  type="select"
                  options={[
                    {
                      key: "1",
                      value: "Yes",
                    },
                    {
                      key: "0",
                      value: "No",
                    },
                  ]}
                  onSelect={(option) =>
                    handleSelectChange("is_customer_employed", option)
                  }
                />
              </div>
            </div>
            <div className={styles.footerBox}>
              <div className={styles.buttonGroup}>
                {formValid && (
                  <Button pending={pending} onClick={handleRunScoring}>
                    Calculate
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.result}>
        {pending && (
          <div className={styles.resultBox}>
            <Loading size={50} />
          </div>
        )}
        <ResultBox />
      </div>
    </div>
  );
};

export default Scoring;
