import Button from "components/button";
import Loading from "components/loading";
import Icon from "icons";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import { useSelector } from "store/hooks";
import styles from "./CommentBox.module.scss";

interface Props {
  pending: boolean;
  editComment: boolean;
  title: string;
  handleSubmitComment: () => void;
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
  setEditComment: Dispatch<SetStateAction<boolean>>;
}

export const CommentBox: FunctionComponent<Props> = ({
  pending,
  editComment,
  title,
  handleSubmitComment,
  setEditComment,
  comment,
  setComment,
}) => {
  const application = useSelector((state) => state.application);

  if (pending) {
    return (
      <div className={styles.commentBox} key={"container"}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={styles.commentBox} key={"container"}>
      <div className={styles.commentBox_title}>
        <div className={styles.icon_container}>
          <Icon name="messageText" />
          {title}
        </div>
        {application?.is_approved === null && !editComment && (
          <button
            type="button"
            className={styles.commentBox_editbtn}
            onClick={() => setEditComment(true)}
            title="edit"
          >
            <Icon name="edit" />
          </button>
        )}
      </div>
      {editComment ? (
        <>
          <ReactTextareaAutosize
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            minRows={4}
            className={styles.commentBox_editor}
            maxLength={2000}
          />
          <div className={styles.commentBox_group}>
            <Button color="blue" onClick={() => handleSubmitComment()}>
              Comment
            </Button>
            <Button
              color="red"
              onClick={() => {
                setEditComment(false);
                setComment(application?.comment ?? "");
              }}
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <div className={styles.commentBox_comment}>
          {editComment ? comment : application?.comment}
        </div>
      )}
    </div>
  );
};
