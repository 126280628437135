export function formatDateTime(isoString: string) {
  const date = new Date(isoString);

  // Format date as dd.mm.YYYY
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${month}.${day}.${year}`;

  // Format time as hh:mm AM/PM
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedTime = `${formattedHours}:${minutes} ${ampm}`;

  return `${formattedDate}, ${formattedTime}`;
}
