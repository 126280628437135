import global from "global";
import request from "request";
import { createModel } from "@rematch/core";

const constants = createModel()({
  state: null as global.constants | null,
  reducers: {
    SET(state, payload: global.constants) {
      return { ...state, ...payload };
    },
    REMOVE() {
      return null;
    },
  },
  effects: (dispatch) => ({
    async FETCH(callback?: () => void) {
      dispatch.constants.REMOVE();
      const response = await request.get("admin/constant_values");
      const data = await response.data;
      dispatch.constants.SET(data);
      callback?.();
    },
  }),
});

export default constants;
