import { Provider as AlertProvider, positions, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "global.scss";
import { ToastContainer } from "react-toastify";
import AppRoutes from "routes";
import store from "store";

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.FADE,
};

const App = () => (
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </AlertProvider>
    <ToastContainer />
  </Provider>
);

export default App;
